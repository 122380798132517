@import '../../../../utils/mixins.scss';

.key-visual-tablet {
  position: relative;
  // height: 100vh;
}

.key-visual-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.key-visual-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.key-visual-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}


.key-visual-tablet__top {
  width: 100%
}
.key-visual-tablet__top-bottom {
  display: flex;
}
.key-visual-tablet__top-bottom-first {
  width: 50%
}
.key-visual-tablet__top-bottom-second {
  width: 50%
}
.key-visual-tablet__bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.key-visual-tablet__bottom-first,
.key-visual-tablet__bottom-second {
  max-height: 280px;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @include Tablet {
    max-height: 270px;
  }

  @include MiniTablet {
    max-height: 190px;
  }
  @include Phone {
    max-height: 150px;
  }
}
