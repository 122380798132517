@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.unique__descr {
  position: fixed;
  flex-direction: column;
  top: 60.2%;
  left: 5.94%;
  display: none;

  @include md {
    left: 5%;
    top: 53%;
  }
  @include xs {
    top: 35%;
    left: 0;
    padding: 0 30px;
  }
  @include Phone {
    padding: 0 16px;
  }
}

.unique__descr-text {
  font-weight: 400;
  font-size: 3vh;
  color: $black;
  max-width: 807px;
  width: 100%;
  top: 58%;
  left: 7%;
  font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;

  @include mdd {
    max-width: 665px;
  }
  @include md {
    max-width: 500px;
  }
  @include xs {
    max-width: 100%;
  }
  @include Tablet {
    font-size: 1.2rem;
    line-height: 30px;
  }
  @include Phone {
    font-size: 1rem;
    line-height: 24px;
  }
}

.unique__play-arrows {
  width: 175px;
  position: absolute;
  bottom: -40%;

  @include Tablet {
    bottom: -20%;
    width: 127px;
    height: 8px;
  }
}

.unique-rectangles-under-text {
  margin: 2% 0 0 -2%;
  max-width: 826px;
  width: 100%;
  height: 24px;
  background: linear-gradient(270.02deg, #E8B449 -0.01%, #FFFFFF 99.22%);

  @include xs {
    margin-left: 0;
    max-width: 100%;
  }
  @include Tablet {
    height: 16px;
  }

  &__border {
    width: 88%;
    height: 24px;
    border: 1px solid $black;
    bottom: 1%;
    position: absolute;

    @include Tablet {
      height: 16px;
      width: 78%;
    }
  }
}
