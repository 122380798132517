@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.main__hero-img {
  position: fixed;
  transform: translate(-50%, -0%);
  left: 53.5%;
  bottom: 0;
  max-width: 533px;
  width: 100%;
  height: 80vh;
  transition: 1.5s;
  object-fit: contain;

  @include xs {
    max-width: 400px;
  }

  @include Tablet {
    max-width: 320px;
    height: 460px;
    left: 50%;
  }

  @include Phone {
    width: 223px;
    height: 364px;
    bottom: 12.66%;
  }
}
