@import '../../../../utils/mixins.scss';

.creation-packs-slide {
  width: 100vw;
  height: 54vh;
  overflow: hidden;
}

.creation-packs {
  display: flex;
  gap: 30px;

  &__wrapper {
    bottom: 8.52%;
    height: 54vh;
  }

  &__title {
    max-width: 1645px;
    width: 100%;
    top: 23%;
    font-size: 3.6rem;
    line-height: 1.2;

    .swiper-main__ampersand {
      font-size: 3.6rem;
      line-height: 1.2;
    }

    @include mdd {
      max-width: 1441px;
    }
  }
}


// animations 


.hiddenCreationsPacks {
  @include animationMixin(hiddenCreationsPacks, 1.6s)
}
  
@keyframes hiddenCreationsPacks {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showCreationsPacks {
  @include animationMixin(showCreationsPacks, 2s)
}
  
@keyframes showCreationsPacks {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenCreationsPacksTitle {
  @include animationMixin(hiddenCreationsPacksTitle, 1.8s)
}

@keyframes hiddenCreationsPacksTitle {
  from {
    top: 23%;
  }
  to {
    top: -77%;
  }
}

.showCreationsPacksTitle {
  @include animationMixin(showCreationsPacksTitle, 1.8s)
}

@keyframes showCreationsPacksTitle {
  from {
    top: -77%;
  }
  to {
    top: 23%;
  }
}
