@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.what-doing__content {
  max-width: 802px;
  width: 100%;
  margin-left: 40px;

  @include xs {
    max-width: 100%;
  }

  &-title {
    font-weight: 700;
    font-size: 4.4vh;
    text-transform: uppercase;
    color: $black2;
    max-width: 626px;
    width: 100%;
    position: relative;
    font-family: "FavoritC-Bold", 'Inter', sans-serif;

    @include md {
      font-size: 2rem;
    }

    @include xs {
      font-size: 3vh;
    }

    @include Tablet {
      font-size: 28px;
    }

    @include Phone {
      font-size: 24px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 3.48%;
  }

  &-button {
    margin-top: 2.5%;
    outline: none;
    border: none;
    padding: 22px 32px;
    // width: 234px;
    background: #FDB813;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 2.5vh;
    line-height: 1;
    color: #2B2B2B;
    font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
    cursor: pointer;

    img {
      height: 2.5vh
    }
    
    span {
      width: max-content;
      text-align: left;
      min-width: 115px;
      padding-left: 24px;
    }

    @include xs {
      padding: 1.4%;
      width: 205px;
    }

    @include Phone {
      width: 146px;
      background-size: 30px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
