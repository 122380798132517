@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.websites__rombh-border {
  position: fixed;
  width: 425.54px;
  height: 425.54px;
  border: 91px solid $yellow;
  transform: rotate(45deg);
  right: 4.7%;
  top: -6%;
  display: none;
}

.websites__title {
  position: fixed;
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 96px;
  color: $black2;
  left: 4%;
  right: 4%;
  top: 25%;
  display: flex;
  display: none;
  flex-direction: column;
  align-items: flex-start;

  @include mdd {
    font-size: 4rem;
    line-height: 1;
  }

  &-text {
    margin-bottom: 5%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url(../../../images/Websites/strokes.svg);
      bottom: -40%;
      left: 0;
      width: 130px;
      height: 9px;
    }
  }
}

.websites__dots {
  position: fixed;
  right: 30.47%;
  top: 11.2%;
  display: none;
}

.websites__strokes {
  position: fixed;
  left: 14.64%;
  top: 32%;
  display: none;
}

.websites__waves {
  position: fixed;
  right: 2.29%;
  top: 2.3%;   
  display: none;
}

.websites__laptops {
  // transform: translate(-50%, 0);
  display: flex;
  // position: fixed;
  bottom: 15.37%;
  left: 50%;
  gap: 32px;
  // display: none;
  padding-top: 2%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.websites__laptop {
  width: 30vw;
  height: 37vh;
}


// animations 


.hiddenWebsitesLaptops {
  @include animationMixin(hiddenWebsitesLaptops, 1.8s)
}

.showWebsitesLaptops {
  @include animationMixin(showWebsitesLaptops, 1.8s)
}

@keyframes hiddenWebsitesLaptops {
  from {
    bottom: 15.37%;
  }
  to {
    bottom: -84.63%;
  }
}

@keyframes showWebsitesLaptops {
  from {
    bottom: -84.63%;
  }
  to {
    bottom: 15.37%;
  }
}



.hiddenWebsitesTitle {
  @include animationMixin(hiddenWebsitesTitle, 1.8s)
}

.showWebsitesTitle {
  @include animationMixin(showWebsitesTitle, 1.8s)
}

@keyframes hiddenWebsitesTitle {
  from {
    top: 25%;
  }
  to {
    top: 125%;
  }
}

@keyframes showWebsitesTitle {
  from {
    top: 125%;
  }
  to {
    top: 25%;
  }
}


.hiddenWebsitesBorderRhomb {
  @include animationMixin(hiddenWebsitesBorderRhomb, 2s)
}

.showWebsitesBorderRhomb {
  @include animationMixin(showWebsitesBorderRhomb, 1.8s)
}

@keyframes hiddenWebsitesBorderRhomb {
  from {
    top: -6%;
  }
  to {
    top: 112%;
  }
}

@keyframes showWebsitesBorderRhomb {
  from {
    top: 106%;
  }
  to {
    top: -6%;
  }
}


.hiddenWebsitesWaves {
  @include animationMixin(hiddenWebsitesWaves, 1.8s)
}

.showWebsitesWaves {
  @include animationMixin(showWebsitesWaves, 1.8s)
}

@keyframes hiddenWebsitesWaves {
  from {
    top: 2.3%; 
  }
  to {
    top: 102.3%;
  }
}

@keyframes showWebsitesWaves {
  from {
    top: 102.3%;
  }
  to {
    top: 2.3%; 
  }
}


.hiddenWebsitesDots {
  @include animationMixin(hiddenWebsitesDots, 1.8s)
}

.showWebsitesDots {
  @include animationMixin(showWebsitesDots, 1.8s)
}

@keyframes hiddenWebsitesDots {
  from {
    top: 11.2%;
  }
  to {
    top: 111.2%;
  }
}

@keyframes showWebsitesDots {
  from {
    top: 111.2%;
  }
  to {
    top: 11.2%; 
  }
}


.hiddenWebsitesStrokes {
  @include animationMixin(hiddenWebsitesStrokes, 1.8s)
}

.showWebsitesStrokes {
  @include animationMixin(showWebsitesStrokes, 1.8s)
}

@keyframes hiddenWebsitesStrokes {
  from {
    top: 32%;
  }
  to {
    top: 132%;
  }
}

@keyframes showWebsitesStrokes {
  from {
    top: 132%;
  }
  to {
    top: 32%; 
  }
}
