@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.our-contacts__title {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 21.63%;
  width: 100%;
  display: none;

  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  line-height: 88px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white;
  margin-top: 34px;    
  font-size: 8.15vh;

  @include md {
    margin-top: 15px;
  }

  @include xs {
    font-size: 4rem;
  }

  @include Tablet {
    font-size: 3.25rem;
    padding-top: 16px;
  }
  @include Phone {
    font-size: 2.25rem;
  }
}

.our-contacts__rectangles-top {
  position: fixed;
  top: 12.43%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: none;
  max-width: 255px;
  width: 100%;
  height: 18vh;

  @include xs {
    width: 180px;
  }

  @include Tablet {
    width: 120px;
  }

  @include Phone {
    width: 93px;
    height: 72px;
  }
}

.our-contacts__content {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 7.87%;
  left: 3.8%;
  display: none;
  height: 50vh;

  @include mdd {
    justify-content: space-between;
    left: 0;
    padding: 0 100px 0 30px;
  }
  @include md {
    padding: 0 30px;
  }
}

.our-contacts__place {
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 100%;
  margin-right: 15.44%;
  max-height: 490px;
  justify-content: space-between;

  @include md {
    max-width: 720px;
    margin-right: 6%;
  }

  &-title {
    font-family: 'FavoritHeavyC', 'Inter', sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 32px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $yellow;
    margin-bottom: 20px;

    @include mdd {
      font-size: 1.2rem;
      line-height: 22px;  
    }

    &-main {
      display: flex;
      flex-direction: column;
    }
  }

  &-address {
    font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 2.8rem;
    letter-spacing: 0.02em;
    color: $black2;
    position: relative;

    // @include mdd {
    //   font-size: 3rem;
    //   line-height: 1.4;
    // }

    &::before {
      content: "";
      width: 200px;
      height: 8px;
      background: $yellow;
      position: absolute;
      bottom: -12%;
      left: 0;

      @include mdd {
        height: 6px;
      }
    }
  }
}

.our-contacts__sites {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 725px;
  width: 100%;

  @include mdd {
    max-width: 650px;
  }
  @include md {
    max-width: 550px;
  }

  &-site {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 35px;
    }
  }
  &-name {
    margin-left: 12px;
    font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 32px;
    color: $black2;

    @include md {
      font-size: 1.2rem;
      line-height: 28px;
    }
  }
}

.our-contacts__email {
  margin-left: 12px;
  font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1;
  color: $black2;
  text-decoration: none;
  white-space: nowrap;

  @include md {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.our-contacts__button-connection {
  font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 32px;
  color: $black2;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 282px;
  height: 72px;
  background: $yellow;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2% 0;

  @include md {
    width: 240px;
    height: 58px;
    font-size: 1.2rem;
  }
}

.our-contacts__person-block {
  display: flex;
  flex-direction: column;
  // max-height: 490px;
  justify-content: space-between;
}

.our-contacts__person-for-connection {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
}

.our-contacts__position {
  font-family: 'FavoritHeavyC', 'Inter', sans-serif;
  font-weight: 900;
  font-size: 2vh;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $yellow;
  margin-bottom: 5px;

  @include md {
    // font-size: 1.2rem;
  }
}

.our-contacts__name {
  font-family: 'FavoritHeavyC', 'Inter', sans-serif;
  font-weight: 900;
  font-size: 2.2vh;
  letter-spacing: 0.04em;
  color: $black2;
  margin-bottom: 5px;
}

.our-contacts__separator {
  width: 2px;
  height: 20px;
  background: #E2E2E2;
  border-radius: 8px;
}


// animations


.showContactUsContent {
  @include animationMixin(showContactUsContent, 1.8s)
}

.hiddenContactUsContent {
  @include animationMixin(hiddenContactUsContent, 1.5s)
}

@keyframes hiddenContactUsContent {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showContactUsContent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



.showContactUsTitle {
  @include animationMixin(showContactUsTitle, 1.8s)
}

.hiddenContactUsTitle {
  @include animationMixin(hiddenContactUsTitle, 1.8s)
}

@keyframes hiddenContactUsTitle {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1500px);
  }
}

@keyframes showContactUsTitle {
  from {
    transform: translateX(-1500px);
  }
  to {
    transform: translateX(0);
  }
}


.showContactUsRectanglesTop {
  @include animationMixin(showContactUsRectanglesTop, 1.8s)
}

.hiddenContactUsRectanglesTop {
  @include animationMixin(hiddenContactUsRectanglesTop, 1.8s)
}

@keyframes showContactUsRectanglesTop {
  from {
    top: -87.57%;
  }
  to {
    top: 12.43%;
  }
}

@keyframes hiddenContactUsRectanglesTop {
  from {
    top: 12.43%;
  }
  to {
    top: -87.57%;
  }
}
