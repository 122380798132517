@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.unique__title {
  width: 100%;
  z-index: 2;
  top: 17%;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  font-family: 'FavoritBookC-Bold', 'Inter', sans-serif;

  @include xs {
    top: 14%;
  }
  @include Tablet {
    top: 16%;
  }
  @include Phone {
    top: 12%;
  }
}

.unique__text {
  font-weight: 700;
  font-size: 8vh;
  text-align: center;
  letter-spacing: 0.1em;
  color: $white;
  max-width: 970px;
  width: 100%;

  @include Tablet {
    font-size: 2.6rem;
  }
  @include Phone {
    font-size: 32px;
  }
}
