@import '../../../../utils/mixins.scss';
@import '../../../../utils/variables.scss';

.team-tablet-card {
  max-width: 220px;
  width: 100%;
  position: relative;

  @include Tablet {
    max-width: 160px;
  }

  &__img {
    max-width: 220px;
    width: 100%;

    @include Tablet {
      max-width: 160px;
    }
  }
  &__info {
    position: absolute;
    z-index: 1;
    bottom: 1%;
    width: 100%;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-name {
      font-family: 'FavoritHeavyC', 'Inter', sans-serif;
      font-weight: 900;
      font-size: 12px;
      line-height: 16px;
      color: $black2;
      text-align: center;
    }

    &-position {
      font-family: 'FavoritLightC', 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: $black2;
      text-align: center;
    }
  }
}