@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.design-installation-tablet {
  position: relative;
  // height: 100vh;
}

.design-installation-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.design-installation-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 700px;
  margin: 0 auto;
}

.design-installation-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}

.design-installation-tablet__title-bottom {
  background: $lightGray2;
  padding: 20px 10px;
  border-bottom: 4px solid $yellow;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 26px;
  text-align: center;
  color: $black2;
  max-width: 220px;
  width: 100%;

  @include MiniTablet {
    max-width: 170px;
    font-size: 18px;
    padding: 12px 10px;
  }
}
