@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.marketing-services-tablet {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url(../../../images/eye_comm_ws.png);
}

.marketing-services-tablet__rhomb-gray {
  position: absolute;
  right: 0;
  top: 0;
  height: 60vh;

  @include Tablet {
    width: 400px;
  }
}

.marketing-services-tablet__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 76px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $white;
  position: absolute;
  z-index: 2;
  left: 20%;
  bottom: 20%;

  @include Tablet {
    font-size: 52px;
    left: 12%;
  }

  @include Phone {
    font-size: 32px;
  }

  &-ampersand {
    color: $yellow;
  }
}

.marketing-services-tablet__rhomb-dies {
  position: absolute;
  left: 0;
  height: 50vh;
  top: 30%;
  width: 41vw;

  @include Tablet {
    width: 58vw;
  }
}

.marketing-services-tablet__rectangles-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.marketing-services-tablet__logo-footer {
  width: 110px;
  position: absolute;
  right: 3%;
  bottom: 3%;

  @include Phone {
    width: 64px;
  }
}

.marketing-services-tablet__play-arrows {
  position: absolute;
  left: 8%;
  top: 20%;

  @include MiniTablet {
    width: 184px;
  }
}

.marketing-services-tablet__waves {
  position: absolute;
  bottom: 12%;
  z-index: 5;
  width: 100px;
  left: 30%;

  @include MiniTablet {
    width: 45px;
    bottom: 15%;
  }
}
