@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.who-are-tablet {
  height: 100vh;
  position: relative;
  min-height: 640px;
}

.who-are-tablet__top {
  background-image: url(../../../images/WhoWeAre/bg-small.png);
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 30vh;

  @include Phone {
    padding: 55px 0 0;
  }
}

.who-are-tablet__top-title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: $white;
  margin-top: 20px;

  @include Phone {
    font-size: 32px;
  }
}

.who-are-tablet__top-rectangles {
  width: 200px;
  height: 16vh;

  @include Phone {
    height: 10vh;
    width: 93px;
  }
}

.who-are-tablet__content {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
  text-align: center;
  color: $black;
  padding: 0 30px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 60%;
  left: 50%;
  width: 100%;

  @include MiniTablet {
    font-size: 18px;
    gap: 12px;
  }
  @include Phone {
    font-size: 16px;
    line-height: 24px;
  }
}

.who-are-tablet__play-arrows {
  position: absolute;
  left: 5%;
  bottom: 9%;

  @include Phone {
    width: 127px;
    bottom: 6%;
  }
}

.who-are-tablet__logo-footer {
  position: absolute;
  width: 110px;
  bottom: 5%;
  right: 5%;

  @include Phone {
    width: 65px;
    bottom: 3%;
    right: 3%;
  }
}
