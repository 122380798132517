@import './utils/mixins.scss';
@import './utils/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Source+Sans+Pro&display=swap');

@font-face {
  font-family: 'FavoritBookC';
  src: url('./fonts/FavoritBookC.eot?#iefix') format('embedded-opentype'),
       url('./fonts/FavoritBookC.woff') format('woff'),
       url('./fonts/FavoritBookC.woff2') format('woff2'),
}

@font-face {
  font-family: 'FavoritC';
  src: url('./fonts/FavoritC.eot?#iefix') format('embedded-opentype'),
       url('./fonts/FavoritC.woff') format('woff')
       url('./fonts/FavoritC.woff2') format('woff2')
}

@font-face {
  font-family: 'FavoritC-Bold';
  src: url('./fonts/FavoritC-Bold.eot?#iefix') format('embedded-opentype'),
       url('./fonts/FavoritC-Bold.woff') format('woff'),
       url('./fonts/FavoritC-Bold.woff2') format('woff2'),
}

@font-face {
  font-family: 'FavoritLightC';
  src: url('./fonts/FavoritLightC.eot?#iefix') format('embedded-opentype'),
       url('./fonts/FavoritLightC.woff') format('woff'),
       url('./fonts/FavoritLightC.woff2') format('woff2'),
}

@font-face {
  font-family: 'FavoritHeavyC';
  src: url('./fonts/FavoritHeavyC.eot?#iefix') format('embedded-opentype'),
       url('./fonts/FavoritHeavyC.woff') format('woff'),
       url('./fonts/FavoritHeavyC.woff2') format('woff2'),
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

body,
html {
  height: 100%;
}

span {
  will-change: bottom, top, transform;
}


.App {
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
  position: relative;
  background-image: url(./images/eye_comm_ws.png);
}

a {
  text-decoration: none;
}

.none {
  display: none !important;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.grid {
  display: grid !important;
}


// TABLET swiper styles 

.main-swiper-tablet {
  overflow: hidden;
  position: relative;
  margin: 0 17%;

  @include lg-min {
    margin: 0 14%;
  }

  @include TabletMax {
    margin: 0 10%;
  }
  @include Tablet {
    margin: 0;
  }

  &__title {
    font-family: 'FavoritC-Bold', 'Inter', sans-serif;
    font-weight: 700;
    font-size: 50px;
    letter-spacing: 0.1em;
    color: $black2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 2% 6%;

    @include Tablet {
      font-size: 36px;
    }

    @include MiniTablet {
      font-size: 22px;
    }
  }

  .swiper-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } 

  .swiper-slide {
    padding: 0 40px;

    @include Phone {
      padding: 0 26px;
    }
  
    img {
      animation-play-state: paused;
      
      &:hover {
        animation-play-state: unset;
      }
    }
  }

.swiper-button-next {
  width: 65px;
  height: 65px;
  position: absolute;
  right: 10px;
  top: 41%;
  margin-right: 3px;
  transform: rotate(-45deg);
  background-color: $yellow;
  cursor: pointer;

  @include MiniTablet {
    width: 40px;
    height: 40px;
  }
  @include Phone {
    width: 32px;
    height: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 32px;
    transform: translate(-50%, -50%) rotate(-135deg);
    left: 50%;
    top: 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2L3 18L19 34' stroke='%232B2B2B' stroke-width='4'/%3E%3C/svg%3E%0A");
    margin-left: 2px;
    margin-top: 3px;

    @include MiniTablet {
      width: 10px;
      height: 34px;
      margin-left: 0px;
      margin-top: 0;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1L2 7L8 13' stroke='%232B2B2B' stroke-width='2'/%3E%3C/svg%3E%0A");
    }
  }
}

.swiper-button-prev {
  width: 65px;
  height: 65px;
  transform: rotate(135deg);
  position: absolute;
  left: 10px;
  margin-left: 3px;
  top: 41%;
  background-color: $yellow;
  cursor: pointer;

  @include MiniTablet {
    width: 40px;
    height: 40px;
  }
  @include Phone {
    width: 32px;
    height: 32px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 32px;
    transform: translate(-50%, -50%) rotate(-135deg);
    left: 50%;
    top: 55%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2L3 18L19 34' stroke='%232B2B2B' stroke-width='4'/%3E%3C/svg%3E%0A");
    
    @include MiniTablet {
      width: 10px;
      height: 34px;
      margin-left: 0px;
      margin-top: 0;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1L2 7L8 13' stroke='%232B2B2B' stroke-width='2'/%3E%3C/svg%3E%0A");
    }
  }
}

  .swiper-button-disabled {
    background-color: $lightGray;
    cursor: default;
  }
}

.swiper-title-tablet__underline {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    background-color: $yellow;
    height: 5px;
    width: 170px;

    @include Tablet {
      width: 64px;
    }
  }
}

.swiper-main-tablet__ampersand {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 0.1em;
  color: $yellow;

  @include Tablet {
    font-size: 36px;
  }

  @include MiniTablet {
    font-size: 22px;
  }
}

// DESKTOP swiper styles

.main-swiper {
  max-width: 1777px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding: 0 10px;
  
  &__wrapper {
    width: 100%;
    position: fixed;
    bottom: 8.2%;
    display: none;
    height: 62vh;
  }

  .swiper-wrapper {
      display: flex;
  }
  
  .swiper-slide {
    padding: 0 60px;
  
    @include md {
      padding: 0 46px;
    }
    
    img {
      animation-play-state: paused;
      
      &:hover {
        animation-play-state: unset;
      }
    }
  }

  .swiper-button-next {
      width: 85px;
      height: 85px;
      position: absolute;
      right: 10px;
      top: 41%;
      margin-right: 18px;
      transform: rotate(-45deg);
      background-color: $yellow;
      cursor: pointer;

      @include md {
        width: 65px;
        height: 65px;
        margin-right: 14px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 32px;
        transform: translate(-50%, -50%) rotate(-135deg);
        left: 50%;
        top: 50%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2L3 18L19 34' stroke='%232B2B2B' stroke-width='4'/%3E%3C/svg%3E%0A");
        margin-left: 2px;
        margin-top: 3px;
        
        @include md {
          transform: translate(-50%, -50%) rotate(-135deg) scale(0.75);
        }
      }
  }

  .swiper-button-prev {
      width: 85px;
      height: 85px;
      transform: rotate(135deg);
      position: absolute;
      left: 10px;
      margin-left: 18px;
      top: 41%;
      background-color: $yellow;
      cursor: pointer;

      @include md {
        width: 65px;
        height: 65px;
        margin-left: 14px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 32px;
        transform: translate(-50%, -50%) rotate(-135deg);
        left: 50%;
        top: 55%;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='36' viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2L3 18L19 34' stroke='%232B2B2B' stroke-width='4'/%3E%3C/svg%3E%0A");
        
        @include md {
          transform: translate(-50%, -50%) rotate(-135deg) scale(0.75);
        }
      }
    }

  .swiper-button-disabled {
    background-color: $lightGray;
    cursor: default;
  }
}

.swiper-main__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.5rem;
  letter-spacing: 0.1em;
  color: $black2;
  position: fixed;
  top: 18.5%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: none;
  text-align: center;
  width: 100%;

  @include mdd {
    font-size: 3rem;
  }
}

.swiper-title__underline {
  position: relative;

  &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      background-color: $yellow;
      height: 7px;
      width: 154px;

      @include md {
        height: 5px;
      }
  }
}

.swiper-main__ampersand {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4.5rem;
  letter-spacing: 0.1em;
  color: $yellow;

  @include mdd {
    font-size: 3rem;
  }
}



// background image animation 

.transformBg {
  @include animationMixin(transformBg, 2s)
}

.transformBgReverse {
  @include animationMixin(transformBgReverse, 2s)
}

.transformBgTeamDescr {
  @include animationMixin(transformBgTeamDescr, 2s)
}

.transformBgTeamDescrReverse {
  @include animationMixin(transformBgTeamDescrReverse, 2s)
}

.transformBgForward {
  @include animationMixin(transformBgForward, 2s)
}

.transformBgPageTeam {
  @include animationMixin(transformBgPageTeam, 1.6s)
}

.transformBgPageTeamReverse {
  @include animationMixin(transformBgPageTeamReverse, 2s)
}

@keyframes transformBg {
  from {
    height: 100vh;
  }
  to {
    height: 38vh;
  }
}

@include Tablet {
  @keyframes transformBgForward {
    from {
      height: 28vh !important;
    }
    to {
      height: 28vh !important;
    }
  }
}

@keyframes transformBgForward {
  from {
    height: 38vh;
  }
  to {
    height: 38vh;
  }
}


@keyframes transformBgReverse {
  from {
    height: 38vh;
  }
  to {
    height: 100vh;
  }
}

@keyframes transformBgTeamDescr {
  from {
    height: 38vh;
  }
  to {
    height: 74.3vh;
  }
}

@keyframes transformBgTeamDescrReverse {
  from {
    height: 74.3vh;
  }
  to {
    height: 38vh;
  }
}



@keyframes transformBgPageTeam {
  from {
    height: 74.3vh;
  }
  to {
    height: 0;
  }
}

@keyframes transformBgPageTeamReverse {
  from {
    height: 0;
  }
  to {
    height: 74.3vh;
  }
}



.transformBgPageEyesEurope {
  @include animationMixin(transformBgPageEyesEurope, 1.5s)
}

.transformBgPageEyesEuropeReverse {
  @include animationMixin(transformBgPageEyesEuropeReverse, 1.8s)
}


@keyframes transformBgPageEyesEurope {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}

@keyframes transformBgPageEyesEuropeReverse {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}



.transformBgPageWhatDoing {
  @include animationMixin(transformBgPageWhatDoing, 1.5s)
}

.transformBgPageWhatDoingReverse {
  @include animationMixin(transformBgPageWhatDoingReverse, 1.5s)
}


@keyframes transformBgPageWhatDoing {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}

@keyframes transformBgPageWhatDoingReverse {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}


.transformBgPageConceptDev {
  @include animationMixin(transformBgPageConceptDev, 1.8s)
}

.transformBgPageConceptDevReverse {
  @include animationMixin(transformBgPageConceptDevReverse, 1.8s)
}


@keyframes transformBgPageConceptDev {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}

@keyframes transformBgPageConceptDevReverse {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}


.transformBgPageLogoIdentity {
  @include animationMixin(transformBgPageLogoIdentity, 2s)
}

.transformBgPageLogoIdentityReverse {
  @include animationMixin(transformBgPageLogoIdentityReverse, 2s)
}


@keyframes transformBgPageLogoIdentity {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}

@keyframes transformBgPageLogoIdentityReverse {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}


.transformBgSwipers {
  @include animationMixin(transformBgSwipers, 2s)
}

.transformBgSwipersReverse {
  @include animationMixin(transformBgSwipersReverse, 2s)
}


@keyframes transformBgSwipers {
  from {
    height: 0;
  }
  to {
    height: 0;
  }
}

@keyframes transformBgSwipersReverse {
  from {
    height: 0;
  }
  to {
    height: 0;
  }
}


.transformBgMarketing {
  @include animationMixin(transformBgMarketing, 2s)
}

.transformBgMarketingReverse {
  @include animationMixin(transformBgMarketingReverse, 2s)
}


@keyframes transformBgMarketing {
  from {
    height: 0;
  }
  to {
    height: 100vh;
  }
}

@keyframes transformBgMarketingReverse {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}

.transformBgQueenMary {
  @include animationMixin(transformBgQueenMary, 2s)
}

.transformBgMarketingReverse {
  @include animationMixin(transformBgMarketingReverse, 2s)
}


@keyframes transformBgQueenMary {
  from {
    height: 100vh;
  }
  to {
    height: 100vh;
  }
}

@keyframes transformBgMarketingReverse {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}


.transformBgOurClients {
  @include animationMixin(transformBgOurClients, 1.6s)
}

.transformBgOurClientsReverse {
  @include animationMixin(transformBgOurClientsReverse, 2s)
}

@keyframes transformBgOurClients {
  from {
    height: 100vh;
  }
  to {
    height: 38vh;
  }
}

@keyframes transformBgOurClientsReverse {
  from {
    height: 38vh;
  }
  to {
    height: 100vh;
  }
}
