@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.content-animations__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1;
  letter-spacing: 0.1em;
  color: $black2;
  position: fixed;
  top: 23%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: none;
  text-align: center;

  max-width: 1500px;
  width: 100%;
  will-change: opacity, top, transform;

  @include mdd {
    font-size: 3.5rem;
    line-height: 65px;
  }
  @include md {
    font-size: 3rem;
    line-height: 54px;
    max-width: 1170px;
  }
}

.content-animations__content {
  display: flex;
  gap: 32px;
  max-width: 1777px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  height: 60vh;
  justify-content: center;

  @include md {
    gap: 15px;
  }
}

.content-animations__wrapper {
  width: 100%;
  position: fixed;
  bottom: 4.6%;
  display: none;
  padding: 0 30px;
}

.content-animations__block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.content-animation__img {
  width: 100%;
  height: 28vh;

  &-last {
    height: 14vh;
    object-fit: cover;
    cursor: pointer;
    width: 100%;
  }
}

.content-animation__rhomb {
  width: 80px;
  height: 80px;
  background: $yellow;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 50%;
  top: 50%;
  cursor: pointer;
  overflow: hidden;

  @include mdd {
    width: 60px;
    height: 60px;
  }

  &::before {
    content: "";
    width: 25px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-45deg);
    left: 50%;
    top: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='51' viewBox='0 0 28 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.5685 35.6784L14.2156 48M14.2156 48L1.86266 35.6783M14.2156 48L14.2156 -5.39964e-07' stroke='%232B2B2B' stroke-width='3'/%3E%3C/svg%3E%0A");

    @include mdd {
      left: 45%;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='30' viewBox='0 0 20 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.5685 35.6784L14.2156 48M14.2156 48L1.86266 35.6783M14.2156 48L14.2156 -5.39964e-07' stroke='%232B2B2B' stroke-width='3'/%3E%3C/svg%3E%0A");
    }
  }
}

.content-animation__block-watch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 28vh;
}

.content-animation__watch-arrow {
  width: 55px;
}

.content-animation__watch-button {
  outline: none;
  border: none;
  background-color: $yellow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  max-width: 570px;
  width: 100%;
  max-height: 110px;
  height: 100%;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.8vh;
  line-height: 40px;
  color: $black2;
  cursor: pointer;
  

  @include md {
    height: 90px;
    // font-size: 1.6rem;
  }
}


// animations


.hiddenContentAnimation {
  @include animationMixin(hiddenContentAnimation, 2s)
}

@keyframes hiddenContentAnimation {
  from {
    bottom: 4.6%;
  }
  to {
    bottom: -95.4%;
  }
}

.showContentAnimation {
  @include animationMixin(showContentAnimation, 2s)
}

@keyframes showContentAnimation {
  from {
    bottom: -95.4%;
  }
  to {
    bottom: 4.6%;
  }
}


.transformToTopContentAnimation {
  @include animationMixin(transformToTopContentAnimation, 2s)
}

@keyframes transformToTopContentAnimation {
  from {
    bottom: 4.6%;
  }
  to {
    bottom: 104.6%;
  }
}

.transformToDownContentAnimation {
  @include animationMixin(transformToDownContentAnimation, 2s)
}

@keyframes transformToDownContentAnimation {
  from {
    bottom: 104.6%;
  }
  to {
    bottom: 4.6%;
  }
}


.hiddenContentAnimationTitle {
  @include animationMixin(hiddenContentAnimationTitle, 2s)
}

@keyframes hiddenContentAnimationTitle {
  from {
    top: 23%;
  }
  to {
    top: 123%;
  }
}

.showContentAnimationTitle {
  @include animationMixin(showContentAnimationTitle, 2s)
}

@keyframes showContentAnimationTitle {
  from {
    top: 123%;
  }
  to {
    top: 23%;
  }
}


.transformToTopContentAnimationTitle {
  @include animationMixin(transformToTopContentAnimationTitle, 2s)
}

@keyframes transformToTopContentAnimationTitle {
  from {
    top: 23%;
  }
  to {
    top: -77%;
  }
}

.transformToDownContentAnimationTitle {
  @include animationMixin(transformToDownContentAnimationTitle, 2s)
}

@keyframes transformToDownContentAnimationTitle {
  from {
    top: -77%;
  }
  to {
    top: 23%;
  }
}
