@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.enjoy-tablet {
  position: relative;
  height: 100vh;
  background-image: url(../../../images/eye_comm_ws.png);

  .copyright-block {
    position: absolute;
    border-top: 1px solid #999999;
    padding: 15px;
    font-size: 12px;
    font-family: 'FavoritC', sans-serif;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    color: #6b6b6b;
    background-color: #B5B3B2;
  
    .developed {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #6b6b6b;

      @include Tablet {
        gap: 10px;
        
        span {
          width: min-content;
        }
      }
    }
  }
}

.enjoy-tablet__title {
  position: absolute;
  bottom: 22%;
    left: 5%;

  &-text {
    position: relative;
    font-family: 'FavoritC-Bold', sans-serif;
    font-weight: 700;
    color: $white;
    z-index: 2;
    font-size: 80px;
    line-height: 86px;

    @include MiniTablet {
      font-size: 40px;
      line-height: 44px;
    }
  }

  &-scretch {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
  }
}

.enjoy-tablet__main-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 45%;
  width: 64vw;

  @include Tablet {
    width: 80vw;
  }
  @include Phone {
    width: 95vw;
  }
}

.enjoy-tablet__rectangles-bottom {
  position: absolute;
  left: -3px;
  bottom: 43px;
}

.enjoy-tablet__logo-footer {
  width: 110px;
  position: absolute;
  right: 3%;
  bottom: 8%;

  @include Phone {
    width: 64px;
  }
}
