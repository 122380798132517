@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.team-descr-tablet {
  height: 100vh;
  position: relative;
  min-height: 700px;
}

.team-descr-tablet__container {
  background-image: url(../../../images/WhoWeAre/bg-small.png);
  background-position: top;
  height: 75vh;
}

.team-descr-tablet__wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 54%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.team-descr-tablet__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  color: $white;
  margin-bottom: 1.2%;

  @include Phone {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 2.5%;
  }
}

.team-descr-tablet__descr {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: $black2;
  margin-bottom: 2.8%;
  padding: 0 30px;

  @include Phone {
    font-size: 16px;
    line-height: 24px;
    padding: 0 16px;
  }
}

.team-descr-tablet__main-img {
  width: 55vw;
  // height: 50vh;

  @include Phone {
    width: 80vw;
  }
}

.team-descr-tablet__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 92%;
  right: 4%;
  left: 4%;
  bottom: 3%;
}

.team-descr-tablet__footer-play-arrows {
  @include Phone {
    width: 127.33px;
    height: 8px;
  }
}

.team-descr-tablet__footer-logo {
  width: 110px;

  @include Phone {
    width: 64px;
  }
}
