@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.websites-tablet {
  position: relative;
  height: 100vh;
  background-image: url(../../../images/eye_comm_ws.png);
  width: 100%;

  &__top {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 11%;
    left: 5%;

    &-title {
      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 46px;
      color: $black2;
      margin-bottom: 2%;
      z-index: 2;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-image: url(../../../images/Websites/strokes.svg);
        bottom: -40%;
        left: 0;
        width: 130px;
        height: 9px;

        @include Phone {
          width: 60px;
          height: 4px;
          bottom: -20%;
        }
      }

      @include Tablet {
        font-size: 36px;
      }

      @include Phone {
        font-size: 28px;
      }
    }

    &-strokes {
      @include Phone {
        width: 58px;
      }
    }
  }
}

.websites-tablet__rhomb {
  position: absolute;
  right: 0;
  width: 17vw;
  top: 12%;
  height: 32vh;

  @include Phone {
    width: 25vw;
    top: 9%;
  }
}

.websites-tablet__content {
  display: flex;
  transform: translate(0%, 180%);
  gap: 24px;
  align-items: center;
  justify-content: center;

  @include TabletMax {
    transform: translate(0%, 40%);
    flex-direction: column;
  }

  @include Tablet {
    padding: 0 16px;
    gap: 15px;
    transform: translate(0%, 50%);
  }
}

.websites-tablet__laptop {
  height: 24vh;
  // width: 36vw;

  @include TabletMax {
    // width: 42vw;
    height: 20vh;
  }
  
  @include Tablet {
    // width: 46vw;
    height: 18vh;
  }
  @include Phone {
    width: 62vw;
  }
  @media (max-width: 600px) {
    width: 50vw;
  }
  @include xss {
    width: 85%;
  }
}
