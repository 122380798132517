@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.person-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 23vh;

  &:hover {
    .person-card__info {
        opacity: 1;
    }
  }

  &__img {
    height: 23vh;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -1px;
    width: 100%;
    padding: 6px 0;
    opacity: 0;
    transition: .5s;


    &-name {
      font-weight: 900;
      font-size: 0.75rem;
      line-height: 16px;
      text-align: center;
      color: $black2;
      font-family: "FavoritC-Bold", 'Inter', sans-serif;

      @include Phone {
        font-size: 12px;
        line-height: 16px;
      }
    }
    
    &-position {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 16px;
      text-align: center;
      color: $black2;
      font-family: "FavoritLightC", 'Source Sans Pro', sans-serif;

      @include Phone {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
