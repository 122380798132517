@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.team__wrapp {
  position: fixed;
  width: 100%;
  bottom: 4.2%;
  display: none;
  height: 79vh;
  background-color: $white;

  @include Tablet {
    padding: 0 8px;
    gap: 8px;
  }
}

.team__content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1774px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  gap: 15px;
  max-height: 880px;
}


// animations 


.animateTeamPageDown {
  @include animationMixin(animateTeamPageDown, 2s)
}

.animateTeamPageUp {
  @include animationMixin(animateTeamPageUp, 2s)
}

@keyframes animateTeamPageDown {
  from {
    transform: translatey(0)
  }
  to {
    transform: translateY(100vh);
  }
}

@keyframes animateTeamPageUp {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translatey(0)
  }
}



.animateTeamCardInfoHidden {
  @include animationMixin(animateTeamCardInfoHidden, 2s)
}

.animateTeamCardInfoBlock {
  @include animationMixin(animateTeamCardInfoBlock, 2s)
}

@keyframes animateTeamCardInfoHidden {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animateTeamCardInfoBlock {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.transformTeamPageUp {
  @include animationMixin(transformTeamPageUp, 2s)
}

@keyframes transformTeamPageUp {
  from {
    bottom: 4.2%;
  }
  to {
    bottom: 104.2%;
  }
}

.transformTeamPageDown {
  @include animationMixin(transformTeamPageDown, 2s)
}

@keyframes transformTeamPageDown {
  from {
    bottom: 104.2%;
  }
  to {
    bottom: 4.2%;
  }
}
