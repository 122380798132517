@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.who-content__text {
  display: none;
  flex-direction: column;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  padding: 0 30px;

  &-line {
    font-weight: 400;
    font-size: 3vh;
    color: $black;
    text-align: center;
    margin-bottom: 3%;
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;

    @include xs {
      font-size: 1.25rem;
    }
    @include Tablet {
      font-size: 1.2rem;
    }
    @include Phone {
      font-size: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
