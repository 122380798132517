@import '../../utils/variables.scss';
@import '../../utils/mixins.scss';

.popup-feedback {
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);
  z-index: 2500;
  height: 100%;
  width: 100%;
  position: fixed;
  animation-name: closePopup;
  animation-duration: 0.6s;
  left: 0;
  top: 0;

  &__none {
    animation-name: closePopup;
    animation-duration: 0.6s;
  }


  .inputs-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vh;
    gap: 32px;

    @include Tablet {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.5vh;
      gap: 2.5vh;
    }
  }

  .input-wrapp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    position: relative;

    @include Tablet {
      width: 100%;
    }

    input {
      background: $white;
      border: 1px solid #D9D9D9;
      outline: none;
      width: 100%;
      padding: 20px;
      font-size: 2.2vh;
      height: 6vh;

      @include Tablet {
        padding: 14px;
        font-size: 16px;
        height: 5vh;
      }

      @include Phone {
        height: 4.3vh;
        padding: 5px;
        font-size: 2vh;
      }
    }
  }

  .message-error {
    color: red;
    font-size: 1.8vh;
    position: absolute;
    bottom: -30%;
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;

    @include Phone {
      font-size: 1.6vh;
    }
  }
  

  .input-title {
    margin-bottom: 1vh;
    font-family: 'FavoritC', 'Inter', sans-serif;
    font-weight: 400;
    // font-size: 24px;
    font-size: 2.2vh;
    color: $black2;

    @include Tablet {
      font-size: 2vh;
      margin-bottom: 0.5vh;
    }
  }

  .textarea-wrapp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3.7vh;

    @include Tablet {
      margin-bottom: 2.5vh;
    }
  }

  .popup-feedback-textarea {
    // max-width: 744px;
    width: 100%;
    height: 184px;
    background: $white;
    border: 1px solid #D9D9D9;
    outline: none;
    padding: 20px;
    resize: none;
    height: 17vh;
    font-size: 2.2vh;

    @include Tablet {
      padding: 14px;
      font-size: 16px;
    }
    @include Phone {
      padding: 5px;
      font-size: 2vh;
    }
  }
}

.popup-feedback-close {
  position: absolute;
  background-color: #9D9D9D;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  right: 70px;
  top: 40px;
  cursor: pointer;

  @include Tablet {
    width: 60px;
    height: 60px;
    right: 35px;
    top: 30px;
  }
  @include Phone {
    width: 40px;
    height: 40px;
    right: 5%;
    top: 3%;
  }

  img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    @include Tablet {
      width: 18px;
    }
    @include Phone {
      width: 14px;
    }
  }
}

.popup-feedback-container {
  max-width: 872px;
  width: 100%;
  background-color: $white;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  // padding: 56px 64px 64px;
  padding: 5vh 3.33% 5vh;
  height: 65vh;

  @include xs {
    width: calc(100% - 10%);
  }
  @include Tablet {
    padding: 20px 25px;
    height: 62vh;
  }
  @include Phone {
    padding: 20px 16px;
    height: 60vh;
  }
}

.popup-feedback-caption {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  // font-size: 48px;
  font-size: 4.4vh;
  color: $black2;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 2.25vh;
  white-space: nowrap;

  @include Tablet {
    font-size: 3vh;
    margin-bottom: 2vh;
    gap: 10px;
  }
  @include Phone {
    margin-bottom: 1.5vh;
    gap: 7px;
  }
}

.popup-feedback-text {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  // font-size: 20px;
  font-size: 1.9vh;
  line-height: 28px;
  color: #6F6F6F;
  margin-bottom: 3vh;

  @include Tablet {
    font-size: 1.7vh;
    line-height: 1.4;
    margin-bottom: 2vh;
  }
}

.send-request {
  background-color: $yellow;
  // padding: 20px 0;
  font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
  line-height: 32px;
  color: $black2;
  border: none;
  outline: none;
  display: flex;
  margin-left: auto;
  height: 6.5vh;
  cursor: pointer;

  width: 100%;
  max-width: 258px;
  justify-content: center;
  align-items: center;

  @include Tablet {
    height: 5.5vh;
    max-width: 230px;
    font-size: 2vh;
    line-height: 1;
  }

  @include Phone {
    max-width: 190px;
  }
}



@keyframes closePopup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
