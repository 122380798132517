@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.our-clients-tablet {
  height: 100vh;
  position: relative;
  min-height: 840px;
}

.our-clients-tablet__top {
  background-image: url(../../../images/WhoWeAre/bg-small.png);
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 30vh;

  @include Phone {
    padding: 55px 0 0;
  }
}

.our-clients-tablet__top-title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: $white;
  margin-top: 20px;

  @include Phone {
    font-size: 32px;
  }
}

.our-clients-tablet__top-rectangles {
  width: 200px;
  height: 16vh;

  @include Phone {
    height: 10vh;
    width: 93px;
  }
}

.our-clients-tablet__content {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
  text-align: center;
  color: $black;
  padding: 0 60px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 63%;
  left: 50%;
  width: 100%;

  @include Tablet {
    padding: 0 30px;
  }

  @include MiniTablet {
    font-size: 18px;
    gap: 12px;
    padding: 0 16px;
    top: 66%;
  }
  @include Phone {
    font-size: 16px;
    line-height: 24px;
  }

  &-worldwide {
    font-size: 2.3vh;
    font-family: 'FavoritHeavyC', 'Inter', sans-serif;
    font-weight: 900;
    color: $black2;
    width: max-content;
    text-align: center;
    margin: 0 auto;
    position: relative;

    @include MiniTablet {
      margin-bottom: 6%;
      font-size: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: $yellow;
      width: 46%;
      height: 4px;
      bottom: -6px;
      right: 0;
    }
  }

  &-block-world {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include MiniTablet {
      margin-bottom: 6%;
    }

    img {
      height: 4.5vh;
      margin: 0 10px;

      @include MiniTablet {
        height: 3.5vh;
      }
    }
  }

  &-ukraine {
   font-size: 2.3vh;
   font-family: 'FavoritHeavyC', 'Inter', sans-serif;
   font-weight: 900;
   color: $black;
   width: max-content;
   text-align: center;
   margin: 0 auto;
   position: relative;

  @include MiniTablet {
    margin-bottom: 6%;
    font-size: 20px;
  }

    &::after {
      content: "";
      position: absolute;
      background-color: $yellow;
      width: 46%;
      height: 4px;
      bottom: -6px;
      right: 0;
    }
  }

  &-block-ukraine {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @include MiniTablet {
      justify-content: space-around;
    }

    img {
      margin: 0 10px;

      @include Tablet {
        height: 8vh;
      }
      @include MiniTablet {
        height: 6vh;
      }
    }
  }

  &-block-general {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    img {
      margin: 0 10px;

      @include Tablet {
        height: 5vh;
        justify-content: center;
      }

      @include MiniTablet {
        width: auto;
      }
    }
    
    
    @include MiniTablet {
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;

      img {
        &:last-child {
          margin-top: 18px;
        }
      }
    }
  }
}

.our-clients-tablet__room8group {
  @include TabletMax {
    width: 23vw !important;
  }
  @include MiniTablet {
    width: 30vw !important;
  }
  @include Tablet {
    width: 35vw !important;
    margin-top: 20px !important;
  }
  @include Phone {
    width: 42vw !important;
  }
}