@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.logo-identity-tablet {
  position: relative;
}

.logo-identity-tablet__slide {
  overflow: hidden;
  width: 100%;
  // display: flex;
  // flex-direction: column;

  &-bottom {
    img {
      @include Phone {
        height: 22vh;
        width: 100%;
      }
    }
  }
}

.logo-identity-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;

  &-second {
    display: grid;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
  }
}

.logo-identity-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}


.logo-identity-second__top {
  display: flex;

  img {
    width: 40%;
  }
}

.logo-identity-second__top-bottom {
  width: 100%;
}

.logo-identity-second__top-block {
  display: flex;
  width: 60%;
  background-color: $yellow;

  &-text {
    font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: $black2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: left;
    padding: 0 30px;

    @include Phone {
      font-size: 12px;
    }
  }
}

.logo-identity-second__bottom {
  width: 100%;
  background-color: #F2F2F2;
  padding: 17% 0;

  &-text {
    font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: $black2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 0 30px;

    @include Phone {
      font-size: 14px;
    }
  }
}