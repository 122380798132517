@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.enjoy__rectangles {
  position: fixed;
  right: 3%;
  top: -15.5%;
  width: 360px;
  height: 360px;
  background: $yellow;
  transform: rotate(45deg);
  z-index: 2;
  display: none;

  @include mdd {
    width: 250px;
    height: 250px;
  }
}

.enjoy__rectangle-gradient {
  width: 322px;
  height: 24px;
  position: absolute;
  right: 40%;
  top: 110%;
  background: linear-gradient(270deg, #FDB813 -0.03%, rgba(253, 184, 19, 0) 102.2%);
  transform: rotate(0deg);
}

.enjoy__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  right: 20%;
  top: 114%;
}

.enjoy__rectangle-yellow {
  position: fixed;
  height: 85vh;
  left: 1.2%;
  bottom: 3.86%;
  display: none;
  width: 54vw;
}

.enjoy__title {
  position: fixed;
  max-width: 423px;
  font-family: 'FavoritC-Bold' , sans-serif;
  font-weight: 700;
  font-size: 7.5rem;
  color: $white;
  left: 3.5%;
  bottom: 28%;
  z-index: 1;
  display: none;

  @include mdd {
    font-size: 6.5rem;
  }
  @include md {
    font-size: 5rem;
  }

  &-experience {
    position: fixed;
    bottom: 28%;
    right: 10.10%;
    font-family: 'FavoritC-Bold', sans-serif;
    font-weight: 700;
    font-size: 7.5rem;
    color: $white;
    z-index: 1;
    display: none;

    @include mdd {
      font-size: 6.5rem;
    }
    @include md {
      font-size: 5rem;
    }  
  }
}

.enjoy__logo-big {
  position: fixed;
  right: 10.53%;
  top: 32.8%;
  display: none;

  @include mdd {
    width: 440px;
  }
  @include md {
    width: 280px;
  }
}

.enjoy__dots {
  position: fixed;
  top: 30%;
  left: 11.6%;
  display: none;
}

.enjoy__strokes {
  position: fixed;
  top: 25.76%;
  left: 14.9%;
  display: none;
}

.enjoy__scretch {
  position: fixed;
  bottom: 30%;
  right: 8.85%;
  display: none;
}
.enjoy__logo-footer {
  position: fixed;
  right: 4%;
  bottom: 9%;
  display: none;
}

.enjoy__main-img {
  position: fixed;
  bottom: 0;
  left: 14.7%;
  display: none;
}

.copyright-block {
  display: none;
  position: fixed;
  border-top: 1px solid #999999;
  padding: 15px;
  font-size: 14px;
  font-family: 'FavoritC', sans-serif;
  width: 100%;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  color: #6b6b6b;
  
  .developed {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #6b6b6b;
  }
}



// animations 


.hiddenEnjoyFaceImg {
  @include animationMixin(hiddenEnjoyFaceImg, 2s)
}

@keyframes hiddenEnjoyFaceImg {
  from {
    left: 14.7%;
  }
  to {
    left: 114.7%;
  }
}

.showEnjoyFaceImg {
  @include animationMixin(showEnjoyFaceImg, 2s)
}

@keyframes showEnjoyFaceImg {
  from {
    left: 114.7%;
  }
  to {
    left: 14.7%;
  }
}


.hiddenEnjoyRhombYellow {
  @include animationMixin(hiddenEnjoyRhombYellow, 2s)
}

@keyframes hiddenEnjoyRhombYellow {
  from {
    left: 1.2%;
  }
  to {
    left: 101.2%;
  }
}

.showEnjoyRhombYellow {
  @include animationMixin(showEnjoyRhombYellow, 2s)
}

@keyframes showEnjoyRhombYellow {
  from {
    left: 101.2%;
  }
  to {
    left: 1.2%;
  }
}


.hiddenEnjoyDots {
  @include animationMixin(hiddenEnjoyDots, 2s)
}

@keyframes hiddenEnjoyDots {
  from {
    left: 11.6%;
  }
  to {
    left: 111.6%;
  }
}

.showEnjoyDots {
  @include animationMixin(showEnjoyDots, 2s)
}

@keyframes showEnjoyDots {
  from {
    left: 111.6%;
  }
  to {
    left: 11.6%;
  }
}



.hiddenEnjoyStrokes {
  @include animationMixin(hiddenEnjoyStrokes, 2s)
}

@keyframes hiddenEnjoyStrokes {
  from {
    left: 14.9%;
  }
  to {
    left: 114.9%;
  }
}

.showEnjoyStrokes {
  @include animationMixin(showEnjoyStrokes, 2s)
}

@keyframes showEnjoyStrokes {
  from {
    left: 114.9%;
  }
  to {
    left: 14.9%;
  }
}


.hiddenEnjoyLogoBig {
  @include animationMixin(hiddenEnjoyLogoBig, 2s)
}

@keyframes hiddenEnjoyLogoBig {
  from {
    right: 10.53%;
  }
  to {
    right: 110.53%;
  }
}

.showEnjoyLogoBig {
  @include animationMixin(showEnjoyLogoBig, 2s)
}

@keyframes showEnjoyLogoBig {
  from {
    right: 110.53%;
  }
  to {
    right: 10.53%;
  }
}



.hiddenEnjoyRectanglesTop {
  @include animationMixin(hiddenEnjoyRectanglesTop, 2s)
}

@keyframes hiddenEnjoyRectanglesTop {
  from {
    top: -15.5%;
  }
  to {
    top: -84.5%;
  }
}

.showEnjoyRectanglesTop {
  @include animationMixin(showEnjoyRectanglesTop, 2s)
}

@keyframes showEnjoyRectanglesTop {
  from {
    top: -115.5%;
  }
  to {
    top: -15.5%;
  }
}


.hiddenEnjoyDies {
  @include animationMixin(hiddenEnjoyDies, 2s)
}

@keyframes hiddenEnjoyDies {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showEnjoyDies {
  @include animationMixin(showEnjoyDies, 2s)
}

@keyframes showEnjoyDies {
  from {
     opacity: 0;
  }
  to {
     opacity: 1;
  }
}


.hiddenEnjoyTitleExperiance {
  @include animationMixin(hiddenEnjoyTitleExperiance, 2s)
}

@keyframes hiddenEnjoyTitleExperiance {
  from {
    right: 10.10%;
  }
  to {
    right: 110.10%;
  }
}

.showEnjoyTitleExperiance {
  @include animationMixin(showEnjoyTitleExperiance, 2s)
}

@keyframes showEnjoyTitleExperiance {
  from {
    right: 110.10%;
  }
  to {
    right: 10.10%;
  }
}


.hiddenEnjoyTitleMain {
  @include animationMixin(hiddenEnjoyTitleMain, 2s)
}

@keyframes hiddenEnjoyTitleMain {
  from {
    left: 3.5%;
  }
  to {
    left: 103.5%;
  }
}

.showEnjoyTitleMain {
  @include animationMixin(showEnjoyTitleMain, 2s)
}

@keyframes showEnjoyTitleMain {
  from {
    left: 103.5%;
  }
  to {
    left: 3.5%;
  }
}




.hiddenCopyright {
  @include animationMixin(hiddenCopyright, 2s)
}

@keyframes hiddenCopyright {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showCopyright {
  @include animationMixin(showCopyright, 2s)
}

@keyframes showCopyright {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
