@import "../../../utils/variables.scss";
@import "../../../utils/mixins.scss";

.who-ukraine {
  transform: translateY(35%);

  @include Tablet {
    display: none;
  }
}

.who__play-arrows {
  position: fixed;
  bottom: 8%;
  left: 4.3%;
  display: none;

  @include Tablet {
    width: 127px;
    height: 8px;
    bottom: 6%;
  }
  @include Phone {
    bottom: 4%;
  }
}

.who__logo-footer {
  display: none;
  position: fixed;
  right: 4%;
  bottom: 2%;

  @include xs {
    width: 80px;  
    right: 2%;
  }

  @include Phone {
    width: 64px;
    right: 1%;
    bottom: 1%;
  }
}


// animations

.hiddenTitle {
  @include animationMixin(hiddenTopTitle, 1.5s)
}

.showTitle {
  @include animationMixin(showTopTitle, 2s)
}

@keyframes hiddenTopTitle {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-500px);
    opacity: 0;
  }
}

@keyframes showTopTitle {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(0);
  }
}



.showContentText {
  @include animationMixin(hiddenContentText, 0.8s)
}

.hiddenContentText {
  @include animationMixin(showContentText, 0.8s)
}

@keyframes hiddenContentText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showContentText {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.hiddenDiesWho {
  @include animationMixin(hiddenDies, 1s)
}

.showDiesWho {
  @include animationMixin(showDies, 1s)
}

@keyframes hiddenDies {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showDies {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
