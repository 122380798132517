@import '../../utils/variables.scss';
@import '../../utils/mixins.scss';

.header {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 2%;
  left: 4%;

  @include xs {
    width: 100%;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(12px);
    padding: 12px 30px;
    top: 0;
    left: 0;
  }
  @include Phone {
    padding: 12px 16px;
  }

  &__menu {
    position: relative;
    outline: none;
    border: none;
    margin-left: 40px;
    cursor: pointer;

    &-border {
      width: 111px;
      height: 64px;
      padding: 16px 20px;
      border: 1px solid $borderWhite;
      position: absolute;
      z-index: 1;
      top: 4px;
      left: 4px;

      @include Tablet {
        width: 95px;
      }

      @include Phone {
        width: 78px;
        height: 40px;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      color: $black;
      width: 111px;
      padding: 16px 20px;
      background: $yellow;
      position: relative;
      z-index: 2;
      font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;

      @include Tablet {
        width: 95px;
        font-size: 20px;
      }

      @include Phone {
        font-size: 16px;
        line-height: 24px;

        padding: 9px 15px;

        width: 78px;
        height: 40px;
      }
    }
  }

  &__logo {
    &-img {
      width: 268px;

      @include Tablet {
        width: 180px;
      }

      @include Phone {
        width: 128px;
      }
    }
  }
}
