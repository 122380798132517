@import "../../../utils/variables.scss";
@import "../../../utils/mixins.scss";

.main-content__waves {
  position: fixed;
  left: 5%;
  bottom: 20%;

  @include xs {
    display: none;
  }
}

.main-content__dotted-img {
  position: fixed;
  left: 46%;
  top: 13%;
  transform: rotate(180deg);

  @include xs {
    display: none;
  }
}

.main-content__dots-img {
  position: fixed;
  left: 39%;
  top: 17%;
  transform: rotate(180deg);

  @include xs {
    display: none;
  }
}

.main-content__logo-footer {
  position: fixed;
  right: 4%;
  bottom: 4%;

  @include Tablet {
    width: 100px;
    right: 3%;
    bottom: 3%;
  }

  @include Phone {
    width: 63px;
  }
}

.hiddenSculpture {
  @include animationMixin(hidden, 2s)
}

.showSculpture {
  @include animationMixin(show, 2s)
}


@keyframes hidden {
  from {
    bottom: 0;
  }
  to {
    bottom: -999px
  }
}

@keyframes show {
  from {
    bottom: -999px
  }
  to {
    bottom: 0
  }
}


.hiddenRhomb {
  @include animationMixin(hiddenRhomb, 2s)
}

.showRhomb {
  @include animationMixin(showRhomb, 2s)
}


@keyframes hiddenRhomb {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showRhomb {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenDies {
  @include animationMixin(hiddenAllDies, 1s)
}

.showDies {
  @include animationMixin(showRhomb, 3s)
}



@keyframes hiddenAllDies {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showAllDies {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
