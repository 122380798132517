@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.eyes-europe-tablet {
  background-image: url(../../../images/eye_comm_ws.png);
  height: 100vh;
  position: relative;
  min-height: 640px;

  &__text {
    font-family: 'FavoritBookC', sans-serif;
    font-weight: 400;
    font-size: 2vh;
    line-height: 1.4;
    color: #2B2B2B;
    margin-top: 2.5%;

    @include Phone {
      font-size: 16px;
    }
  }
}

.eyes-europe-tablet__content {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  position: absolute;
  top: 13%;

  @include Phone {
    padding: 0 16px;
  }
}

.eyes-europe-tablet__logo {
  max-width: 280px;

  @include Phone {
    max-width: 175px;
  }
}

.eyes-europe-tablet__main-img {
  width: 50vw;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 0;
  height: 50vh;

  @include Tablet {
    height: 48vh;
    width: auto;
  }

  @include MiniTablet {
    height: 36vh;
  }
  @include xss {
    width: 90vw;
  }
}
