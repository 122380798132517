@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.eyes-europe__rombh-border {
  position: fixed;
  width: 25vw;
  height: 25vw;
  border: 100px solid $yellow;
  transform: rotate(45deg);
  right: 5%;
  top: -7%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.eyes-europe__text {
  max-width: 670px;
  width: 100%;
  font-weight: 400;
  font-size: 3vh;
  line-height: 1.4;
  color: $black;
  font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;

  @include mdd {
    max-width: 600px;
  }
  @include md {
    max-width: 510px;
    font-size: 2.6vh;
  }
  @include xs {
    max-width: 100%;
    font-size: 24px;
  }
  @include Phone {
    font-size: 16px;
  }
}

.eyes-europe__logo {
  max-width: 407px;
  width: 100%;

  @include mdd {
    width: 330px;
  }
  @include Tablet {
    width: 280px;
  }
  @include Phone {
    width: 185px;
  }
}

.eyes-europe__main-img {
  position: fixed;
  right: 13.4%;
  bottom: 0;
  width: 37vw;
  display: none;
  height: 87%;

  @include xs {
    display: none !important;
  }

  &-tablet {
    display: none;

    @include xs {
      display: block;
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
    }
    @include Phone {
      max-width: 328px;
    }
  }
}

.eyes-europe__play-arrows {
  position: fixed;
  bottom: 9.7%;
  left: 38.2%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.eyes-europe__dots {
  position: fixed;
  right: 8.3%;
  bottom: 32.8%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.eyes-europe__strokes {
  position: fixed;
  right: 9.3%;
  bottom: 24.5%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.eyes-europe__waves {
  position: fixed;
  right: 3.75%;
  bottom: 11.94%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.eyes-europe__title {
  display: flex;
  flex-direction: column;
  gap: 38px;
  position: fixed;
  top: 24%;
  left: 3.9%;
  display: none;

  @include xs {
    left: 0;
    width: 100%;
    padding: 0 30px;
    top: 11%;
    gap: 15px;
  }
  @include Phone {
    padding: 0 16px;
  }
}


// animations


.hiddenImgEyesEurope {
  @include animationMixin(hiddenImgEyesEurope, 2s)
}

.showImgEyesEurope {
  @include animationMixin(showImgEyesEurope, 2s)
}

@keyframes hiddenImgEyesEurope {
  from {
    opacity: 1;
    bottom: 0%;
  }
  to {
    opacity: 1;
    bottom: -100%;
  }
}

@keyframes showImgEyesEurope {
  from {
    opacity: 1;
    bottom: -100%;
  }
  to {
    opacity: 1;
    bottom: 0%;
  }
}


.hiddenRombhBorderEyesEurope {
  @include animationMixin(hiddenRombhBorderEyesEurope, 1.2s)
}

.showRombhBorderEyesEurope {
  @include animationMixin(showRombhBorderEyesEurope, 1.8s)
}

@keyframes hiddenRombhBorderEyesEurope {
  from {
    opacity: 1;
    top: -7%;
  }
  to {
    opacity: 1;
    top: -50%;
  }
}


@keyframes showRombhBorderEyesEurope {
  from {
    opacity: 1;
    top: -50%;
  }
  to {
    opacity: 1;
    top: -7%;
  }
}


.hiddenPlayArrowsEyesEurope {
  @include animationMixin(hiddenPlayArrowsEyesEurope, 2s)
}

.showPlayArrowsEyesEurope {
  @include animationMixin(showPlayArrowsEyesEurope, 2s)
}

@keyframes hiddenPlayArrowsEyesEurope {
  from {
    opacity: 1;
    bottom: 9.7%;
  }
  to {
    opacity: 1;
    bottom: -109.7%;
  }
}

@keyframes showPlayArrowsEyesEurope {
  from {
    opacity: 1;
    bottom: -109.7%;
  }
  to {
    opacity: 1;
    bottom: 9.7%;
  }
}



.hiddenDotsEyesEurope {
  @include animationMixin(hiddenDotsEyesEurope, 2s)
}

.showDotsEyesEurope {
  @include animationMixin(showDotsEyesEurope, 2s)
}

@keyframes hiddenDotsEyesEurope {
  from {
    opacity: 1;
    bottom: 32.8%;
  }
  to {
    opacity: 1;
    bottom: -67.2%;
  }
}

@keyframes showDotsEyesEurope {
  from {
    opacity: 1;
    bottom: -67.2%;
  }
  to {
    opacity: 1;
    bottom: 32.8%;
  }
}



.hiddenStrokesEyesEurope {
  @include animationMixin(hiddenStrokesEyesEurope, 2s)
}

.showStrokesEyesEurope {
  @include animationMixin(showStrokesEyesEurope, 2s)
}

@keyframes hiddenStrokesEyesEurope {
  from {
    opacity: 1;
    bottom: 24.5%;
  }
  to {
    opacity: 1;
    bottom: -75.5%;
  }
}

@keyframes showStrokesEyesEurope {
  from {
    opacity: 1;
    bottom: -75.5%;
  }
  to {
    opacity: 1;
    bottom: 24.5%;
  }
}


.hiddenWavesEyesEurope {
  @include animationMixin(hiddenWavesEyesEurope, 2s)
}

.showWavesEyesEurope {
  @include animationMixin(showWavesEyesEurope, 2s)
}

@keyframes hiddenWavesEyesEurope {
  from {
    opacity: 1;
    bottom: 11.94%;
  }
  to {
    opacity: 1;
    bottom: -88.06%;
  }
}

@keyframes showWavesEyesEurope {
  from {
    opacity: 1;
    bottom: -88.06%;
  }
  to {
    opacity: 1;
    bottom: 11.94%;
  }
}



.transformToTopTitleEyesEurope {
  @include animationMixin(transformToTopTitleEyesEurope, 2s)
}

.transformToDownTitleEyesEurope {
  @include animationMixin(transformToDownTitleEyesEurope, 2s)
}

@keyframes transformToTopTitleEyesEurope {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes transformToDownTitleEyesEurope {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




.hiddenTitleEyesEurope {
  @include animationMixin(hiddenTitleEyesEurope, 2s)
}

.showTitleEyesEurope {
  @include animationMixin(showTitleEyesEurope, 2s)
}

@keyframes hiddenTitleEyesEurope {
  from {
    top: 24%;
  }
  to {
    top: 124%;
  }
}

@keyframes showTitleEyesEurope {
  from {
    top: 124%;
  }
  to {
    top: 24%;
  }
}
