.corporate-book-tablet {
  position: relative;
}

.corporate-book-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.corporate-book-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.corporate-book-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}


.corporate-book-tablet__top {
  width: 100%;
}
.corporate-book-tablet__top-bottom {
  display: flex;
}
.corporate-book-tablet__top-bottom-first {
width: 50%;
}
.corporate-book-tablet__top-bottom-second {
width: 50%;
}
.corporate-book-tablet__bottom {
  width: 100%;
}