@mixin animationMixin($animationName, $animationDuration) {
  animation: $animationName;
  animation-duration: $animationDuration;
  animation-fill-mode: forwards;
}


@mixin xss {
  @media (max-width: 360px) {
    @content;
  }
}

@mixin Phone {
  @media (max-width: 460px) {
    @content;
  }
}

@mixin MiniTablet {
  @media (max-width: 580px) {
    @content;
  }
}

@mixin Tablet {
  @media (max-width: 780px) {
    @content;
  }
}

@mixin TabletMax {
  @media (max-width: 970px) {
    @content;
  }
}

@mixin lg-min {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin mdd {
  @media (max-width: 1730px) {
    @content;
  }
}

@mixin mr {
  @media (max-width: 1830px) {
    @content;
  }
}