@import '../../../../utils/mixins.scss';
@import '../../../../utils/variables.scss';

.creation-prod-tablet {
  position: relative;
  // height: 100vh;
}

.creation-prod-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.creation-prod-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.creation-prod-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}

.creation-prod-tablet__title {
  flex-direction: column;

  @include TabletMax {
    font-size: 46px;
  }

  @include Tablet {
    font-size: 36px;
  }

  @include MiniTablet {
    font-size: 26px;
  }

  @include Phone {
    font-size: 20px;
  }

  .swiper-main-tablet__ampersand {
    @include Tablet {
      font-size: 36px;
    }
  
    @include MiniTablet {
      font-size: 26px;
    }
  
    @include Phone {
      font-size: 20px;
    }  
  }
}


.creation-prod-tablet__slide-top {
  display: flex;
}

.creation-prod-tablet__slide-top-img {
  width: 70%;
  position: relative;
  z-index: 2;
  left: 6%;

  &-next {
    left: 8%;
  }
}

.creation-prod-tablet__slide-top-text-block {
  background-color: $yellow;
  color: $black2;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: $black;

  @include Phone {
    font-size: 16px;
  }
}


.creation-prod-tablet__slide-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.creation-prod-tablet__slide-bottom-img {
  width: 60%;
}

.creation-prod-tablet__slide-bottom-text-block {
  background-color: #FAFAFA;
  color: $black2;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: $black;

  @include Phone {
    font-size: 16px;
  }
}