@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.unique__sticky-tape {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 33%;
  display: none;

  @include md {
    width: 400px;
    top: 33%;
  }
  @include xs {
    width: 290px;
  }
  @include Tablet {
    width: 190px;
    top: 24%;
  }
  @include Phone {
    top: 19.5%;
  }
}

.unique-top__rect-group {
  position: fixed;
  right: 1%;
  top: 0;
  display: none;

  @include xs {
    display: none !important;
  }
}

.unique-img-with-rombh {
  position: fixed;
  right: 0;
  bottom: 0;
  display: none;
}

.unique-content__img-main {
  height: 74vh;

  @include mdd {
    width: 770px;
  }
  @include md {
    width: 720px;
  }
  @include xs {
    width: 588px;
    display: none;
  }

  &-tablet {
    display: none;

    @include xs {
      display: block;
      width: 500px;
    }
    @include MiniTablet {
      width: 360px;
    }
  }
}


// animations


.hiddenImgWithRhombUnique {
  @include animationMixin(hiddenImgWithRhombUnique, 2s)
}

.showImgWithRhombUnique {
  @include animationMixin(showImgWithRhombUnique, 2s)
}

@keyframes hiddenImgWithRhombUnique {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateX(900px);
  }
}

@keyframes showImgWithRhombUnique {
  from {
      transform: translateX(900px);
  }
  to {
      transform: translateY(0);
  }
}



.showStickyTape {
  @include animationMixin(showStickyTape, 3.5s)
}

.hiddenStickyTape {
  @include animationMixin(hiddenStickyTape, 0.7s)
}


@keyframes hiddenStickyTape {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showStickyTape {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



.hiddenDescrUnique {
  @include animationMixin(hiddenDescrUnique, 1s)
}

.showDescrUnique {
  @include animationMixin(showDescrUnique, 2s)
}

@keyframes hiddenDescrUnique {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showDescrUnique {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenTitleUnique {
  @include animationMixin(hiddenTitleUnique, 1.8s)
}

.showTitleUnique {
  @include animationMixin(showTitleUnique, 2s)
}


@keyframes hiddenTitleUnique {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-500px);
  }
}

@keyframes showTitleUnique {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(0);
  }
}
