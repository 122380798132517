@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.contact-us-tablet {
  position: relative;
  height: 100vh;

  @include Tablet {
    height: auto;
  }
}

.contact-us-tablet__top {
  background-image: url(../../../images/WhoWeAre/bg-small.png);
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 30vh;

  @include Phone {
    padding: 55px 0 0;
  }
}
  
.contact-us-tablet__top-title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: $white;
  margin-top: 20px;
  text-transform: uppercase;

  @include Phone {
    font-size: 32px;
  }
}
  
.contact-us-tablet__top-rectangles {
  width: 200px;
  height: 16vh;

  @include Phone {
    height: 10vh;
    width: 93px;
  }
}

.contact-us-tablet__content {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 7.87%;
  height: 50vh;
  padding: 0 30px;
  justify-content: space-between;
  gap: 30px;

  @include Tablet {
    flex-direction: column;
    position: static;
    padding: 30px 30px 60px;
    height: auto;
  }

  @include Phone {
    padding: 32px 16px;
  }
}

.contact-us-tablet__place {
  display: flex;
  flex-direction: column;
  max-height: 490px;
  justify-content: space-between;
  gap: 20px;


  &-title {
    font-family: 'FavoritHeavyC', 'Inter', sans-serif;
    font-weight: 900;
    font-size: 1.2rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $yellow;
    margin-bottom: 20px;

    @include Phone {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &-main {
      display: flex;
      flex-direction: column;

      @include Phone {
        margin-bottom: 24px;
      }
    }
  }

  &-address {
    font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: 0.02em;
    color: $black2;
    position: relative;

    @include Tablet {
      font-size: 1.8rem;
    }

    @include Phone {
      font-family: 'FavoritHeavyC', 'Inter', sans-serif;
      font-weight: 900;
      font-size: 20px;
    }

    &::after {
      content: "";
      width: 200px;
      height: 8px;
      background: $yellow;
      position: absolute;
      bottom: -15%;
      left: 0;

      @include TabletMax {
        height: 5px;
        width: 150px;
      }

      @include Phone {
        height: 4px;
        width: 96px;
        bottom: -60%;
      }
    }
  }
}

.contact-us-tablet__sites {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  &-site {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4%;

    @include Tablet {
      margin-bottom: 2%;
    }

    img {
      width: 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &-name {
    margin-left: 12px;
    font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: $black2;
  }
}

.contact-us-tablet__email {
  margin-left: 12px;
  font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: $black2;
  text-decoration: none;
  white-space: nowrap;
}

.contact-us-tablet__button-connection {
  font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 32px;
  color: $black2;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 282px;
  height: 72px;
  background: $yellow;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2% 0;

  @include Tablet {
    display: none;
  }

  &-tablet {
    display: none;

    @include Tablet {
      display: block;
      font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 32px;
      color: $black2;
      display: flex;
      align-items: center;
      justify-content: center;
    
      width: 282px;
      height: 72px;
      background: $yellow;
      border: none;
      outline: none;
      cursor: pointer;
      margin-top: 20px;
      padding: 1.5% 0;

      @include Phone {
        width: 188px;
        height: 56px;

        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.contact-us-tablet__person-block {
  display: flex;
  flex-direction: column;
  max-height: 490px;
  justify-content: space-between;
}

.contact-us-tablet__person-for-connection {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.contact-us-tablet__position {
  font-family: 'FavoritHeavyC', 'Inter', sans-serif;
  font-weight: 900;
  font-size: 1.6vh;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $yellow;
  margin-bottom: 5px;

  @include Phone {
    font-size: 16px;
    line-height: 24px;
  }
}

.contact-us-tablet__name {
  font-family: 'FavoritHeavyC', 'Inter', sans-serif;
  font-weight: 900;
  font-size: 1.8vh;
  letter-spacing: 0.04em;
  color: $black2;
  margin-bottom: 5px;

  @include xs {
    font-size: 2vh;
  }

  @include Phone {
    // font-size: 20px;
    // line-height: 30px;
  }
}
