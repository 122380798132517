@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';
  
.footer__rectangle-gradient {
  width: 249.64px;
  height: 24px;
  position: absolute;
  left: 0;
  bottom: 6%;
  background: linear-gradient(270.02deg, #E8B449 -0.01%, rgba(232, 180, 73, 0) 99.22%);
  transform: rotate(45deg);

  @include Tablet {
    width: 101.53px;
    height: 9.76px;
    bottom: 3.5%;
  }
}

.footer__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  left: -22%;
  bottom: -50%;

  @include Tablet {
    width: 101.53px;
    height: 9.76px;
  }
}
