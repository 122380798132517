@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.concept-dev-tablet {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url(../../../images/eye_comm_ws.png);
}

.concept-dev-tablet__rhomb-gray {
  position: absolute;
  right: 0;
  top: 0;
  width: 500px;
  height: 75vh;

  @include Tablet {
    width: 400px;
  }
}

.concept-dev-tablet__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 80px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $white;
  position: absolute;
  z-index: 2;
  left: 10%;
  bottom: 25%;

  @include Tablet {
    font-size: 54px;
  }

  @include Phone {
    font-size: 32px;
  }

  &-ampersand {
    color: $yellow;
  }
}

.concept-dev-tablet__rhomb-dies {
  position: absolute;
  left: 0;
  height: 50vh;
  top: 20%
}

.concept-dev-tablet__rectangles-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.concept-dev-tablet__logo-footer {
  width: 110px;
  position: absolute;
  right: 3%;
  bottom: 3%;

  @include Phone {
    width: 64px;
  }
}
