@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.team-descr__header-rectangles {
  display: none;
  position: fixed;
  top: -15.5%;
  right: 3%;

  @include mdd {
    right: -4%;
  }
}

.team-descr__img-block {
  z-index: 2;
  width: 988px;
  height: 700px;
}

.team-descr__img-hero {
  position: fixed;
  top: 17.6%;
  left: 6.5%;
  display: none;
  z-index: 2;
  height: 80vh;

  @include mdd {
    width: 620px;
  }
  @include md {
    left: 5.5%;
    width: 470px;
  }
  @include xs {
    left: 25%;
    width: 440px;
  }
  @include Tablet {
    left: 13%;
    width: 380px;
  }
  @include Phone {
    width: 320px;
    height: 320px;
    left: 7%;
  }
  @include xss {
    left: -4%;
  }
}

.team-descr__text {
  font-weight: 400;
  font-size: 3vh;
  line-height: 38px;
  text-align: center;
  color: $black;
  font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
  z-index: 2;

  @include mdd {
    max-width: 800px;
  }
  @include md {
    max-width: 680px;
    font-size: 2.5vh;
  }
  @include xs {
    max-width: 100%;
    right: 0;
    font-size: 2.5vh;
    padding: 0 30px;
    justify-content: center;
    line-height: 30px;
    top: 26%;
  }
  @include Phone {
    padding: 0 16px;
    top: 22%;
    font-size: 16px;
    line-height: 24px;
    top: 19%;
  }
}


.team-descr__waves {
  position: fixed;
  width: 86px;
  top: 15%;
  left: 57%;
  display: none;

  @include xs {
    display: none !important;
  }
}

.team-descr__rectangle-border {
  position: fixed;
  top: 5%;
  left: 3.85%;
  display: none;
  height: 90vh;

  @include mdd {
    width: 860px;
  }
  @include md {
    width: 644px;
  }
  @include xs {
    transform: translate(-50%, -50%);
    left: 50%;
    width: 600px;
  }
  @include Tablet {
    width: 490px;
  }
  @include Phone {
    width: 320px;
  }
}

.team-descr__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 6.5rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.1em;
  color: $white;
  width: 100%;
  margin-bottom: 3%;
  max-width: 800px;
}

.team-descr__title-block {
  position: fixed;
  right: 5%;
  top: 37%;
  display: none;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;

  @include mdd {
    font-size: 6.25rem;
  }
  @include md {
    font-size: 6.25rem;
  }
  @include xs {
    top: 10%;
    font-size: 5rem;
    width: 100%;
    right: 0;
  }
  @include Tablet {
    font-size: 4rem;
  }
  @include Phone {
    font-size: 32px;
    line-height: 44px;
  }
}

.team__sticky-tape {
  position: fixed;
  left: 33%;
  top: 26%;
  display: none;
  z-index: 2;
  height: 14.5vh;

  @include md {
    left: 35%;
    width: 300px;
  }
  @include xs {
    left: 58%;
    width: 280px;
  }
  @include Tablet {
    left: 56%;
    width: 240px;
  }
  @include MiniTablet {
    left: 70%;
  }
  @include Phone {
    left: 62%;
    width: 150px;
    height: 112px;
  }
  @include xss {
    left: 64%;
  }
}

.team-descr__arrows {
  position: fixed;
  bottom: 8%;
  left: 4.3%;
  display: none;

  @include Tablet {
    width: 127px;
    height: 8px;
  }
}

.team-descr__footer {
  display: none;
  position: fixed;
  right: 4%;
  bottom: 4%;
}

.team-descr__wrapper {
  display: none;
}

.team-descr__footer-logo {
  @include Tablet {
    width: 100px;
  }
  @include Phone {
    width: 63px;
  }
}


// animations


.hiddenTitleTeam {
  @include animationMixin(hiddenTitleTeam, 1.8s)
}
 
.showTitleTeam {
  @include animationMixin(showTitleTeam, 2s)
}
 
 
@keyframes hiddenTitleTeam {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(999px);
  }
}

@keyframes showTitleTeam {
  from {
    transform: translateX(999px);
  }
  to {
    transform: translateX(0);
  }
}
 


.hiddenDiesTeam {
  @include animationMixin(hiddenDiesTeam, 1s)
}

.showDiesTeam {
  @include animationMixin(showDiesTeam, 1s)
}

@keyframes hiddenDiesTeam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showDiesTeam {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.hiddenSculptureTeam {
  @include animationMixin(hiddenSculptureTeam, 2s)
}

.showSculptureTeam {
  @include animationMixin(showSculptureTeam, 2s)
}
 
@keyframes hiddenSculptureTeam {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-900px);
  }
}

@keyframes showSculptureTeam {
  from {
    transform: translateX(-900px);
  }
  to {
    transform: translateX(0);
  }
}




.hiddenRectanglesTeam {
  @include animationMixin(hiddenRectanglesTeam, 2s)
}

.showRectanglesTeam {
  @include animationMixin(showRectanglesTeam, 2s)
}

@keyframes hiddenRectanglesTeam {
  from {
    transform: translateY(-15.5%);
  }
  to {
    transform: translateY(-500px);
  }
}

@keyframes showRectanglesTeam {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(-15.5%);
  }
}


.hiddenRectangleBorderTeam {
  @include animationMixin(hiddenRectangleBorderTeam, 0.8s)
}

.showRectangleBorderTeam {
  @include animationMixin(showRectangleBorderTeam, 2s)
}


@keyframes hiddenRectangleBorderTeam {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showRectangleBorderTeam {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.animateStickyTape {
  @include animationMixin(animateStickyTape, 2s)
}

.animateStickyReverse {
  @include animationMixin(animateStickyReverse, 0.8s)
}
  
@keyframes animateStickyTape {
  from {
    transform: translatex(0) translatey(0)
  }
  to {
    transform: translatex(-135%) translatey(190%);
  }
}

@keyframes animateStickyReverse {
  from {
    transform: translatex(-135%) translatey(190%);
  }
  to {
    transform: translatex(0) translatey(0);
    opacity: 0;
  }
}


.animateTeamDescrPageDown {
  @include animationMixin(animateTeamDescrPageDown, 2s)
}

.animateTeamDescrPageUp {
  @include animationMixin(animateTeamDescrPageUp, 2s)
}

@keyframes animateTeamDescrPageDown {
  from {
      transform: translatey(-100vh)
  }
  to {
      transform: translateY(0);
  }
}

@keyframes animateTeamDescrPageUp {
  from {
    transform: translatey(0);
  }
  to {
    transform: translatey(-100vh);
  }
}



// animations transform


.transformToTopTitleTeam {
  @include animationMixin(transformToTopTitleTeam, 2s)
}

@keyframes transformToTopTitleTeam {
  from {
    top: 37%;
  }
  to {
    top: -63%;
  }
}

.transformToDownTitleTeam {
  @include animationMixin(transformToDownTitleTeam, 2s)
}

@keyframes transformToDownTitleTeam {
  from {
    top: -63%;
  }
  to {
    top: 37%;
  }
}

.transformToTopSculptureTeam {
  @include animationMixin(transformToTopSculptureTeam, 2s)
}

@keyframes transformToTopSculptureTeam {
  from {
    top: 17.6%;
  }
  to {
    top: -82.4%;
  }
}

.transformToDownSculptureTeam {
  @include animationMixin(transformToDownSculptureTeam, 2s)
}

@keyframes transformToDownSculptureTeam {
  from {
    top: -82.4%;
  }
  to {
    top: 17.6%;
  }
}



.transformToTopRombhTeam {
  @include animationMixin(transformToTopRombhTeam, 2s)
}

@keyframes transformToTopRombhTeam {
  from {
    top: 5%;
  }
  to {
    top: -95%;
  }
}


.transformToDownRombhTeam {
  @include animationMixin(transformToDownRombhTeam, 2s)
}

@keyframes transformToDownRombhTeam {
  from {
    top: -95%;
  }
  to {
    top: 5%;
  }
}



.transformToTopRectanglesTeam {
  @include animationMixin(transformToTopRectanglesTeam, 2s)
}

@keyframes transformToTopRectanglesTeam {
  from {
    top: -15.5%;
  }
  to {
    top: -84.5%;
  }
}


.transformToDownRectanglesTeam {
  @include animationMixin(transformToDownRectanglesTeam, 2s)
}

@keyframes transformToDownRectanglesTeam {
  from {
    top: -84.5%;
  }
  to {
    top: -15.5%;
  }
}



.transformToTopStickyTapeTeam {
  @include animationMixin(transformToTopStickyTapeTeam, 2s)
}

@keyframes transformToTopStickyTapeTeam {
  from {
    top: 26%;
    transform: translatex(-135%) translatey(190%);
  }
  to {
    top: -74%;
    transform: translatex(-135%) translatey(190%);
  }
}

.transformTeamStickyTape {
  transform: translatex(-135%) translatey(190%);
}


.transformToDownStickyTapeTeam {
  @include animationMixin(transformToDownStickyTapeTeam, 2s)
}

@keyframes transformToDownStickyTapeTeam {
  from {
    top: -74%;
    transform: translatex(-135%) translatey(190%);
  }
  to {
    top: 26%;
    transform: translatex(-135%) translatey(190%);
  }
}



.transformToTopStrokesTeam{
  @include animationMixin(transformToTopStrokesTeam, 2s)
}

@keyframes transformToTopStrokesTeam {
  from {
    top: 30%;
  }
  to {
    top: -70%;
  }
}

.transformToDownStrokesTeam {
  @include animationMixin(transformToDownStrokesTeam, 2s)
}

@keyframes transformToDownStrokesTeam {
  from {
    top: -70%;
  }
  to {
    top: 30%;
  }
}



.transformToTopWavesTeam {
  @include animationMixin(transformToTopWavesTeam, 2s)
}

@keyframes transformToTopWavesTeam {
  from {
    top: 15%;
  }
  to {
    top: -85%;
  }
}

.transformToDownWavesTeam {
  @include animationMixin(transformToDownWavesTeam, 2s)
}

@keyframes transformToDownWavesTeam {
  from {
    top: -85%;
  }
  to {
    top: 15%;
  }
}




.transformToTopDotsTeam {
  @include animationMixin(transformToTopDotsTeam, 2s)
}

@keyframes transformToTopDotsTeam {
  from {
    top: 24%;
  }
  to {
    top: -76%;
  }
}

.transformToDownDotsTeam {
  @include animationMixin(transformToDownDotsTeam, 2s)
}

@keyframes transformToDownDotsTeam {
  from {
    top: -76%;
  }
  to {
    top: 24%;
  }
}



.transformToTopDescrTextTeam {
  @include animationMixin(transformToTopDescrTextTeam, 2s)
}

@keyframes transformToTopDescrTextTeam {
  from {
    top: 54%;
  }
  to {
    top: -46%;
  }
}

.transformToDownDescrTextTeam {
  @include animationMixin(transformToDownDescrTextTeam, 2s)
}

@keyframes transformToDownDescrTextTeam {
  from {
    top: -46%;
  }
  to {
    top: 54%;
  }
}



.transformToTopFooterLogoTeam {
  @include animationMixin(transformToTopFooterLogoTeam, 2s)
}

@keyframes transformToTopFooterLogoTeam {
  from {
    bottom: 4%;
  }
  to {
    bottom: 104%;
  }
}

.transformToDownFooterLogoTeam {
  @include animationMixin(transformToDownFooterLogoTeam, 2s)
}

@keyframes transformToDownFooterLogoTeam {
  from {
    bottom: 104%;
  }
  to {
    bottom: 4%;
  }
}



.transformToTopPlayArrowsTeam {
  @include animationMixin(transformToTopPlayArrowsTeam, 2s)
}

@keyframes transformToTopPlayArrowsTeam {
  from {
    bottom: 8%;
  }
  to {
    bottom: 108%;
  }
}

.transformToDownPlayArrowsTeam {
  @include animationMixin(transformToDownPlayArrowsTeam, 2s)
}

@keyframes transformToDownPlayArrowsTeam {
  from {
    bottom: 108%;
  }
  to {
    bottom: 8%;
  }
}
