@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.dubai-expo-slide {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
}

.dubai-expo {
  display: flex;
  gap: 15px;
  justify-content: center;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 11px;

    img {
      height: 28vh;
      width: 30vw;
    }

    &--1 { 
      height: 28px;
    }

    &--2 {
      width: 50%;
      height: 28vh;
      background-color: $yellow;

      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 2.5vh;
      line-height: 40px;

      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 30px;
    }
    &--3 {
      width: 50%;
      height: 28vh;
    }
    &--5 {
      height: 28vh;
      width: 100%;

      background-color: #FAFAFA;
      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 2.5vh;
      line-height: 40px;

      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 30px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-top {
      display: flex;
      gap: 15px;
      justify-content: space-between;
    }
  }
}

.dubai-expo__title {
  max-width: 1000px;
  width: 100%;
}

.dubai-expo__wrapper {
  bottom: 10.2%;

  .swiper-button-prev,
  .swiper-button-next {
    top: 48%;
  }
}

.dubai-expo__descr {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: $black2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  left: 5.14%;
  bottom: 5.18%;

  @include mdd {
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    width: 364px;
    height: 4px;
    background: $yellow;
    position: absolute;
    bottom: -14px;

    @include mdd {
      width: 270px;
      height: 3px;
      bottom: -8px;
    }
  }
}
  
// animations 


.hiddenDubaiExpo {
  @include animationMixin(hiddenDubaiExpo, 1.5s)
}

@keyframes hiddenDubaiExpo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showDubaiExpo {
  @include animationMixin(showDubaiExpo, 2.4s)
}

@keyframes showDubaiExpo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenDubaiExpoTitle {
  @include animationMixin(hiddenDubaiExpoTitle, 1.6s)
}

@keyframes hiddenDubaiExpoTitle {
  from {
    top: 18.5%;
  }
  to {
    top: -81.5%;
  }
}

.showDubaiExpoTitle {
  @include animationMixin(showDubaiExpoTitle, 2s)
}

@keyframes showDubaiExpoTitle {
  from {
    top: -81.5%;
  }
  to {
    top: 18.5%; 
  }
}



.hiddenDubaiExpoDescrBottom {
  @include animationMixin(hiddenDubaiExpoDescrBottom, 1.6s)
}

@keyframes hiddenDubaiExpoDescrBottom {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-500px);
    opacity: 0;
  }
}

.showDubaiExpoDescrBottom {
  @include animationMixin(showDubaiExpoDescrBottom, 2s)
}

@keyframes showDubaiExpoDescrBottom {
  from {
    transform: translateX(-500px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
