@import '../../../../utils/mixins.scss';
@import '../../../../utils/variables.scss';

.creation-prod-slide-first {
  width: 100vw;
  height: 55vh;
  overflow: hidden;
}

.creation-prod-slide-second {
  width: 100vw;
  height: 55vh;
  overflow: hidden; 
}

.creation-prod__wrapper {
  bottom: 12.87%;
  width: 100%;
  position: fixed;
  display: none;
  height: 47vh;
}

.creation-prod {
  display: flex;
  position: relative;

  &__left {
    display: flex;
    width: 50vw;
    position: relative;
    margin-right: 16px;

    &-img {
      position: absolute;
      z-index: 2;
      width: 33vw;
      height: 46vh;
    }

    &-block {
      width: 17vw;
      height: 46vh;
      background: $yellow;
      position: absolute;
      right: 0;

    span {
      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      padding-left: 56px;
      font-weight: 400;
      font-size: 2rem;
      line-height: 38px;
      color: $black;

      position: absolute;
      top: 45%;
    }
  }
}

  &__right {
    display: flex;
    margin-left: 16px;

    img {
      width: 21vw;
      height: 46vh;
    }

    &-block {
      width: 17vw;
      height: 46vh;
      background: $lightGray2;

      span {
        font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
        padding-left: 56px;
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        color: $black;

        position: absolute;
        top: 45%;
      }
    }
  }
}

.creation-prod__title {
  // max-width: 1400px;
  width: 100%;
  top: 23.5%;
}


.creation-prod-second {
  display: flex;
  position: relative;
  justify-content: center;

  &__left {
    display: flex;

    width: 50vw;
    position: relative;
    margin-right: 16px;

    &-img {
      position: absolute;
      z-index: 2;
      width: 33vw;
      height: 46vh;
    }

    &-block {
      width: 17vw;
      height: 46vh;
      background: $yellow;
      position: absolute;
      right: 0;

      span {
        font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
        padding-left: 56px;
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        color: $black;

        position: absolute;
        top: 45%;
      }
    }
  }

  &__right {
    display: flex;
    margin-left: 16px;

    img {
      width: 21vw;
      height: 46vh;
    }

    &-block {
      width: 19vw;
      height: 46vh;
      background: $lightGray2;

      span {
        font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
        padding-left: 56px;
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        color: $black;

        position: absolute;
        top: 45%;
      }
    }
  }
}



// animations

.hiddenCreationProd {
    @include animationMixin(hiddenCreationProd, 2s)
  }
  
  @keyframes hiddenCreationProd {
    from {
        bottom: 12.87%;
    }
    to {
        bottom: -87.13%;
    }
  }
  
  .showCreationProd {
    @include animationMixin(showCreationProd, 2s)
  }
  
  @keyframes showCreationProd {
    from {
        bottom: -87.13%;
    }
    to {
        bottom: 12.87%;
    }
  }

  .transformToDownCreationProd {
    @include animationMixin(transformToDownCreationProd, 1.6s)
  }

  .transformToTopCreationProd {
    @include animationMixin(transformToTopCreationProd, 1.6s)
  }

  @keyframes transformToTopCreationProd {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
  }

  @keyframes transformToDownCreationProd {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }


  // animation title

  .hiddenCreationProdTitle {
    @include animationMixin(hiddenCreationProdTitle, 2s)
  }
  
  @keyframes hiddenCreationProdTitle {
    from {
        top: 23.5%;
    }
    to {
        top: 123.5%;
    }
  }
  
  .showCreationProdTitle {
    @include animationMixin(showCreationProdTitle, 2s)
  }
  
  @keyframes showCreationProdTitle {
    from {
        top: 123.5%;
    }
    to {
        top: 23.5%;
    }
  }


  .transformToDownCreationProdTitle {
    @include animationMixin(transformToDownCreationProdTitle, 1.6s)
  }

  .transformToTopCreationProdTitle {
    @include animationMixin(transformToTopCreationProdTitle, 1.6s)
  }

  @keyframes transformToTopCreationProdTitle {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
  }

  @keyframes transformToDownCreationProdTitle {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }