@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.unique-tablet {
  height: 100vh;
  position: relative;
  min-height: 700px;
}

.unique-tablet__top {
  background-image: url(../../../images/WhoWeAre/bg-small.png);
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 28vh;
  position: relative;
}

.unique-tablet__top-title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: $white;
  margin-top: 20px;
  text-align: center;
  position: relative;
  z-index: 5;

  @include MiniTablet {
    font-size: 40px;
  }

  @include Phone {
    font-size: 32px;
  }
}

.unique-tablet__top-sticky-tape {
  position: absolute;
  width: 380px;
  height: 104px;
  bottom: -5%;

  @include Tablet {
    height: 90px;
  }

  @include MiniTablet {
    height: 78px;
  }

  @include Phone {
    width: 191.33px;
    height: 56px;
    bottom: -4%;
  }
}


.unique-tablet__content {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 35%;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  width: 100%;

  @include Phone {
    padding: 0 16px;
  }

  &-block {
    display: flex;
    flex-direction: column;
  }

  &-text {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $black;

    @include Tablet {
      font-size: 22px;
    }

    @include MiniTablet {
      font-size: 16px;
      line-height: 24px
    }
  }
}

.unique-tablet__content-play-arrows {
  width: 150px;

  @include Phone {
    width: 110px;
    height: 8px;
  }
}

.unique-tablet__content-rectangles-lines {
  width: 320px;
}

.unique-tablet-rectangles-under-text {
  position: relative;
  margin: 3.5% 0 4%;
  width: 100%;
  height: 24px;
  background: linear-gradient(270.02deg, #E8B449 -0.01%, #FFFFFF 99.22%);

  @include Phone {
    height: 16px;
  }

  &__border {
    width: 88%;
    height: 24px;
    border: 1px solid $black;
    bottom: 1%;
    position: absolute;

    @include Phone {
      height: 16px;
    }
  }
}

.unique-tablet__content-main-img {
  height: 45vh;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  bottom: 0;
}
