@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.marketing-services__rectangles {
  position: fixed;
  right: 3%;
  top: -15.5%;
  width: 360px;
  height: 360px;
  background: $yellow;
  transform: rotate(45deg);
  z-index: 2;
  display: none;

  @include md {
    width: 306px;
    height: 306px;
  }
}

.marketing-services__rectangle-gradient {
  width: 322px;
  height: 24px;
  position: absolute;
  right: 40%;
  top: 110%;
  background: linear-gradient(270deg, #FDB813 -0.03%, rgba(253, 184, 19, 0) 102.2%);
  transform: rotate(0deg);
}

.marketing-services__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  right: 20%;
  top: 114%;
}


.marketing-services__border-gray {
  position: fixed;
  width: 34vw;
  height: 34vw;
  opacity: 0.4;
  border: 160px solid #ACACAD;
  transform: rotate(45deg);
  right: 20%;
  top: -19%;
  z-index: 1;
  display: none;
}

.marketing-services__sculpture {
  position: fixed;
  top: 19%;
  left: 2.78%;
  display: none;
  height: 69vh;
}

.marketing-services__title {
  position: fixed;
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 8.25rem;
  line-height: 160px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  bottom: 20.16%;
  left: 32.34%;
  z-index: 2;
  color: $white;
  display: none;

  @include mdd {
    font-size: 7rem;
    line-height: 126px;
  }
  @include md {
    font-size: 6rem;
    line-height: 110px;
  }

  &-ampersand {
    color: $yellow;
  }
}

.marketing-services__play-arrows {
  position: fixed;
  top: 42.8%;
  right: 16.72%;
  display: none;
}

.marketing-services__scretch {
  position: fixed;
  bottom: 9.4%;
  right: 27.72%;
  z-index: 1;
  display: none;

  @include md {
    right: 10%;
  }
}

.marketing-services__logo {
  position: fixed;
  right: 4%;
  bottom: 4%;
  display: none;
}

.marketing-services__rectangles-bottom {
  display: none;
  bottom: 11%;
  left: 0.5%;
  position: fixed;
}


.marketing-services__rectangle-gradient-bottom {
  width: 322px;
  height: 24px;
  position: absolute;
  left: 0%;
  bottom: 11%;
  background: linear-gradient(270deg, #FDB813 -0.03%, rgba(253, 184, 19, 0) 102.2%);
  transform: rotate(45deg);
}

.marketing-services__rectangle-border-bottom {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  left: -31.5%;
  bottom: -55%;
}


// animations


.hiddenSculptureMarketingServ {
  @include animationMixin(hiddenSculptureMarketingServ, 2s)
}
  
@keyframes hiddenSculptureMarketingServ {
  from {
    left: 2.78%;
  }
  to {
    left: 102.78%;
  }
}
  
.showSculptureMarketingServ {
  @include animationMixin(showSculptureMarketingServ, 2s)
}
  
@keyframes showSculptureMarketingServ {
  from {
    left: 102.78%;
  }
  to {
    left: 2.78%;
  }
}

.transformToTopSculptureMarketingServ {
  @include animationMixin(transformToTopSculptureMarketingServ, 2s)
}

.transformToDownSculptureMarketingServ {
  @include animationMixin(transformToDownSculptureMarketingServ, 2s)
}

@keyframes transformToTopSculptureMarketingServ {
  from {
    top: 19%;
  }
  to {
    top: -81%;
  }
}

@keyframes transformToDownSculptureMarketingServ {
  from {
    top: -81%;
  }
  to {
    top: 19%;
  }
}


  // border gray animation

.hiddenBorderGrayMarketingServ {
  @include animationMixin(hiddenBorderGrayMarketingServ, 2s)
}
    
@keyframes hiddenBorderGrayMarketingServ {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}
    
.showBorderGrayMarketingServ {
  @include animationMixin(showBorderGrayMarketingServ, 2s)
}
  
@keyframes showBorderGrayMarketingServ {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}
  
  
.transformToTopBorderGrayMarketingServ {
  @include animationMixin(transformToTopBorderGrayMarketingServ, 2s)
}

.transformToDownBorderGrayMarketingServ {
  @include animationMixin(transformToDownBorderGrayMarketingServ, 2s)
}

@keyframes transformToTopBorderGrayMarketingServ {
  from {
    top: -19%;
  }
  to {
    top: -119%;
  }
}

@keyframes transformToDownBorderGrayMarketingServ {
  from {
    top: -119%;
  }
  to {
    top: -19%;
  }
}


// title animation

.hiddenTitleMarketingServ {
  @include animationMixin(hiddenTitleMarketingServ, 2s)
}
    
.showTitleMarketingServ {
  @include animationMixin(showTitleMarketingServ, 2s)
}
    
@keyframes hiddenTitleMarketingServ {
  from {
    bottom: 20.16%;
  }
  to {
    bottom: 120.16%;
  }
}

@keyframes showTitleMarketingServ {
  from {
    bottom: 120.16%;
  }
  to {
    bottom: 20.16%;
  }
}

  
.transformToTopTitleMarketingServ {
  @include animationMixin(transformToTopTitleMarketingServ, 2s)
}

.transformToDownTitleMarketingServ {
  @include animationMixin(transformToDownTitleMarketingServ, 2s)
}

@keyframes transformToTopTitleMarketingServ {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1750px);
  }
}

@keyframes transformToDownTitleMarketingServ {
  from {
    transform: translateX(-999px);
  }
  to {
    transform: translateX(0);
  }
}


// top rectangles animation

.hiddenTopRectanglesMarketingServ {
  @include animationMixin(hiddenTopRectanglesMarketingServ, 2s)
}
  
@keyframes hiddenTopRectanglesMarketingServ {
  from {
    top: -15.5%;
  }
  to {
    top: -84.5%;
  }
}
  
.showTopRectanglesMarketingServ {
  @include animationMixin(showTopRectanglesMarketingServ, 2s)
}

@keyframes showTopRectanglesMarketingServ {
  from {
    top: -115.5%;
  }
  to {
    top: -15.5%;
  }
}

.transformToTopRectanglesTopMarketingServ {
  @include animationMixin(transformToTopRectanglesTopMarketingServ, 2s)
}

.transformToDownRectanglesTopMarketingServ {
  @include animationMixin(transformToDownRectanglesTopMarketingServ, 2s)
}

@keyframes transformToTopRectanglesTopMarketingServ {
  from {
    top: -15.5%;
  }
  to {
    top: -115.5%;
  }
}

@keyframes transformToDownRectanglesTopMarketingServ {
  from {
    top: -115.5%;
  }
  to {
    top: -15.5%;
  }
}


//logo footer animation


.hiddenFooterLogoMarketingServ {
  @include animationMixin(hiddenFooterLogoMarketingServ, 2s)
}

@keyframes hiddenFooterLogoMarketingServ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showFooterLogoMarketingServ {
  @include animationMixin(showFooterLogoMarketingServ, 2s)
}

@keyframes showFooterLogoMarketingServ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.transformToTopLogoFooterMarketingServ {
  @include animationMixin(transformToTopLogoFooterMarketingServ, 2s)
}

.transformToDownLogoFooterMarketingServ {
  @include animationMixin(transformToDownLogoFooterMarketingServ, 2s)
}

@keyframes transformToTopLogoFooterMarketingServ {
  from {
    bottom: 4%;
  }
  to {
    bottom: 104%;
  }
}

@keyframes transformToDownLogoFooterMarketingServ {
  from {
    bottom: 104%;
  }
  to {
    bottom: 4%;
  }
}


// play arrows animation


.hiddenPlayArrowsMarketingServ {
  @include animationMixin(hiddenPlayArrowsMarketingServ, 2s)
}
  
@keyframes hiddenPlayArrowsMarketingServ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showPlayArrowsMarketingServ {
  @include animationMixin(showPlayArrowsMarketingServ, 2s)
}
  
@keyframes showPlayArrowsMarketingServ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopPlayArrowsMarketingServ {
  @include animationMixin(transformToTopPlayArrowsMarketingServ, 2s)
}

.transformToDownPlayArrowsMarketingServ {
  @include animationMixin(transformToDownPlayArrowsMarketingServ, 2s)
}

@keyframes transformToTopPlayArrowsMarketingServ {
  from {
    top: 42.8%;
  }
  to {
    top: -57.2%;
  }
}

@keyframes transformToDownPlayArrowsMarketingServ {
  from {
    top: -57.2%;
  }
  to {
    top: 42.8%;
  }
}


// scretch animation


.hiddenScretchMarketingServ {
  @include animationMixin(hiddenScretchMarketingServ, 2s)
}

@keyframes hiddenScretchMarketingServ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showScretchMarketingServ {
  @include animationMixin(showScretchMarketingServ, 2s)
}

@keyframes showScretchMarketingServ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopScretchMarketingServ {
  @include animationMixin(transformToTopScretchMarketingServ, 2s)
}

.transformToDownScretchMarketingServ {
  @include animationMixin(transformToDownScretchMarketingServ, 2s)
}

@keyframes transformToTopScretchMarketingServ {
  from {
    bottom: 9.4%;
  }
  to {
    bottom: 109.4%;
  }
}

@keyframes transformToDownScretchMarketingServ {
  from {
    bottom: 109.4%;
  }
  to {
    bottom: 9.4%;
  }
}

  
// rectangles bottom animation


.hiddenRectanglesBottomMarketingServ {
  @include animationMixin(hiddenRectanglesBottomMarketingServ, 2s)
}
  
@keyframes hiddenRectanglesBottomMarketingServ {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showRectanglesBottomMarketingServ {
  @include animationMixin(showRectanglesBottomMarketingServ, 2s)
}

@keyframes showRectanglesBottomMarketingServ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopRectanglesBottomMarketingServ {
  @include animationMixin(transformToTopRectanglesBottomMarketingServ, 2s)
}

.transformToDownRectanglesBottomMarketingServ {
  @include animationMixin(transformToDownRectanglesBottomMarketingServ, 2s)
}

@keyframes transformToTopRectanglesBottomMarketingServ {
  from {
    bottom: 11%;
  }
  to {
    bottom: 111%;
  }
}

@keyframes transformToDownRectanglesBottomMarketingServ {
  from {
    bottom: 111%;
  }
  to {
    bottom: 11%;
  }
}
