@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.what-doing__rectangle-group {
  position: fixed;
  top: 8.8%;
  right: 22.18%;
  display: none;
  width: 160px;

  @include mdd {
    right: 29.18%;
  }
  @include xs {
    display: none !important;
  }
}

.what-doing__wrapper {
  display: flex;
  grid-template-columns: 100px 1fr 1fr;
  align-items: flex-end;
  position: fixed;
  bottom: 6.2%;
  width: 100%;
  padding: 0 67px;
  display: none;

  @include xs {
    flex-direction: column;
    padding: 0 30px;
    gap: 15px;
    align-items: flex-start;
  }
  @include Tablet {
    bottom: 3%;
  }
  @include Phone {
    padding: 0 16px;
  }
}

.what-doing__title {
  color: $black;
  font-weight: 300;
  font-size: 6.25vh;
  letter-spacing: 0.08em;
  transform: rotate(180deg);
  font-family: "FavoritLightC", 'Source Sans Pro', sans-serif;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  // padding-left: 20px;

  @include md {
    font-size: 5.8vh;
  }

  @include xs {
    transform: rotate(0);
    writing-mode: unset;
    text-orientation: inherit;
    font-size: 40px;
  }
  @include Phone {
    font-size: 16px;
  }

  &-we {
    color: $yellow;
    font-family: "FavoritC-Bold", 'Inter', sans-serif;
    margin-left: 10px;
  }
}

.what-doing__rectangles {
  position: fixed;
  right: -10%;
  top: -10%;
  width: 360px;
  height: 360px;
  background: $yellow;
  transform: rotate(45deg);
  display: none;

  @include md {
    width: 280px;
    height: 280px;
  }

  @include xs {
    display: none !important;
  }
}
  
.what-doing__rectangle-gradient {
  width: 322px;
  height: 24px;
  position: absolute;
  right: 40%;
  top: 105%;
  background: linear-gradient(270.02deg, #E8B449 -0.01%, #FFFFFF 99.22%);
  transform: rotate(0deg)
}
  
.what-doing__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  right: 20%;
  top: 109%;
}



//animations

.hiddenContentWhatDoing {
  @include animationMixin(hiddenContentWhatDoing, 1.8s)
}

@keyframes hiddenContentWhatDoing {
  from {
    bottom: 6.2%;
  }
  to {
    bottom: -93.8%;
  }
}

.showContentWhatDoing {
  @include animationMixin(showContentWhatDoing, 2s)
}

@keyframes showContentWhatDoing {
  from {
    bottom: -93.8%;
  }
  to {
    bottom: 6.2%;
  }
}


.hiddenContentTitleWhatDoing {
  @include animationMixin(hiddenContentTitleWhatDoing, 1.8s)
}

@keyframes hiddenContentTitleWhatDoing {
  from {
    bottom: 41.2%;
  }
  to {
    bottom: -58.8%;
  }
}

.showContentTitleWhatDoing {
  @include animationMixin(showContentTitleWhatDoing, 2s)
}

@keyframes showContentTitleWhatDoing {
  from {
    bottom: -58.8%;
  }
  to {
    bottom: 41.2%;
  }
}


.hiddenRombhWithLinesWhatDoing {
  @include animationMixin(hiddenRombhWithLinesWhatDoing, 1.8s)
  
}

@keyframes hiddenRombhWithLinesWhatDoing {
  from {
    top: 8.8%;
  }
  to {
    top: -91.2%;
  }
}
  
.showRombhWithLinesWhatDoing {
  @include animationMixin(showRombhWithLinesWhatDoing, 2s)
}

@keyframes showRombhWithLinesWhatDoing {
  from {
    top: -91.2%;
  }
  to {
    top: 8.8%;
  }
}


.hiddenTopRectanglesWhatDoing {
  @include animationMixin(hiddenTopRectanglesWhatDoing, 1.8s)
}

@keyframes hiddenTopRectanglesWhatDoing {
  from {
    top: -10%;
  }
  to {
    top: -90%;
  }
}
  
.showTopRectanglesWhatDoing {
  @include animationMixin(showTopRectanglesWhatDoing, 2s)
}

@keyframes showTopRectanglesWhatDoing {
  from {
    top: -90%;
  }
  to {
    top: -10%;
  }
}
