@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.creation-gifts-tablet {
  position: relative;
  // height: 100vh;
}

.creation-gifts-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.creation-gifts-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  gap: 12px;
}

.creation-gifts-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;

  &-title {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.creation-gifts-tablet__top-block-wrapp {
  display: flex;
}

.creation-gifts-tablet__top-block-img {
  width: 30%;
  object-fit: contain;
}

.creation-gifts-tablet__top-block-text {
  background-color: $yellow;
  padding: 15px;
  width: 70%;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: $black2;
  display: flex;
  align-items: center;
  justify-content: center;

  @include Phone {
    font-size: 12px;
  }
}

.creation-gifts-tablet__top-block-bottom {

  &-img {
    width: 100%;
  }
}



.creation-gifts-tablet__bottom-block-wrapp {
  display: flex;
}

.creation-gifts-tablet__bottom-block-img {
  width: 50%;
  object-fit: cover;
}

.creation-gifts-tablet__bottom-block-text {
  background-color: $yellow;
  padding: 15px;
  width: 50%;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: $black2;
  display: flex;
  align-items: center;
  justify-content: center;

  @include Phone {
    font-size: 12px;
  }
}

.creation-gifts-tablet__bottom-block-bottom {
  width: 100%;
  position: relative;
  background-color: $white;
  display: flex;
}

.creation-gifts-tablet__bottom-block-bottom-img {
  width: 25%;
}

.creation-gifts-tablet__bottom-block-bottom-text {
  background-color: #fafafa;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: $black2;

  @include Phone {
    font-size: 12px;
  }
}