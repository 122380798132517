@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.main__bg-rectangle {
  width: 34vw;
  height: 34vw;
  background: $yellow;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 46%;
  top: 51%;
  position: absolute;

  @include xs {
    left: 50%;
    top: 50%;
    width: 50vw;
    height: 50vw;
  }

  @include Phone {
    top: 42%;
  }
}
