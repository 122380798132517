@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.dubai-expo-tablet {
  position: relative;
  // height: 100vh;
}

.dubai-expo-tablet__slide {
  overflow: hidden;
  width: 100%;

  &-last {
    width: 100%;
  }
}

.dubai-expo-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 700px;
  margin: 0 auto;
  gap: 5px;
}

.dubai-expo-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}

.dubai-expo-tablet__title-bottom {
  background: $lightGray2;
  padding: 20px 10px;
  border-bottom: 4px solid $yellow;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 26px;
  text-align: center;
  color: $black2;
  max-width: 280px;
  width: 100%;

  @include MiniTablet {
    max-width: 210px;
    font-size: 18px;
    padding: 12px 10px;
  }
}

.dubai-expo-tablet__slide-wrapp {
  display: flex;
  gap: 5px;

  img {
    width: 50%;
  }
}

.dubai-expo-tablet__wrapp-block {
  background-color: $yellow;
  display: flex;
  padding: 0 10px;


  &-text {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: $black2;
    display: flex;
    align-items: center;
    justify-content: center;

    @include Phone {
      font-size: 12px;
    }
  }
}


.dubai-expo-tablet__slide-last {
  background-color: #FAFAFA;

  &-text {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: $black2;
    padding: 40px;

    @include Phone {
      font-size: 12px;
      padding: 12px;
    }
  }
}
