@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.main-page-tablet {
  position: relative;
  background-image: url(../../../images/eye_comm_ws.png);
  overflow: hidden;
  height: 100vh;
  min-height: 640px;
}

.main-page-tablet__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 50%;
  z-index: 2;

  @include Phone {
    top: 38%;
  }
}

.main-page-tablet__title-main {
  font-family: 'FavoritC-Bold', sans-serif;
  font-weight: 700;
  font-size: 9vw;
  color: $white;
  white-space: nowrap;
  margin-bottom: 20px;
  display: flex;
  gap: 25px;

  @include Tablet {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin-bottom: 0;
    font-size: 11vw;
  }
}

.main-page-tablet__title-wrapp {
  display: flex;
  white-space: nowrap;
  gap: 15px;
}

.main-page-tablet__title-center {
  font-family: 'FavoritC', sans-serif;
  font-weight: 400;
  font-size: 72px;
  text-transform: uppercase;
  color: $white;

  @include Tablet {
    font-size: 50px;
    line-height: 1.5;
  }
  @include MiniTablet {
    font-size: 36px;
  }
}

.main-page-tablet__title-gray  {
  font-family: 'FavoritC', sans-serif;
  font-weight: 400;
  font-size: 72px;
  text-transform: uppercase;
  color: $white;
  opacity: 0.24;

  @include Tablet {
    font-size: 50px;
    line-height: 1.5;
  }
  @include MiniTablet {
    font-size: 36px;
  }
}


.main-page-tablet__main-img {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0);
  left: 50%;
  width: 36vw;
  z-index: 2;
  height: 65vh;
  object-fit: contain;

  @include Phone {
    bottom: 13%;
    width: 60vw;
    height: 54vh;
  }
}

.main-page-tablet__rectangles-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-page-tablet__rhomb {
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  left: 50%;
  top: 50%;
  background-color: $yellow;
  width: 50vw;
  height: 50vw;
  z-index: 1;

  @include Phone {
    top: 37%;
  }
}


.main-page-tablet__logo-footer {
  position: absolute;
  right: 3%;
  bottom: 3%;
  width: 110px;
  height: 110px;
  
  @include MiniTablet {
    width: 64px;
    height: 64px;
  }
}
