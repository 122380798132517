@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.key-visual-slide {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
}

.key-visual-slide-second {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
}

.key-visual {
  display: flex;
  align-items: center;
  justify-content: center;

  &-swiper {
    .swiper-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__title {
    max-width: 1260px;
    width: 100%;
    top: 20%;
  }
}

.key-visual__left {
  display: flex;
  flex-direction: column;

  &-bottom {
    display: flex;
    align-items: center;
  }
}
  
.key-visual__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.key-visual-second {
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-visual__left-top {
  width: 48vw;
  height: 29vh;
  object-fit: cover;
}

.key-visual__left-bottom-first {
  width: 24vw;
  height: 29vh;
  object-fit: cover;
  object-position: left;
}
.key-visual__left-bottom-second {
  width: 24vw;
  height: 29vh;
  object-fit: cover;
  object-position: left;
}
.key-visual__right-top {
  width: 37vw;
  height: 29vh;
  object-fit: cover;
}
.key-visual__right-bottom {
  width: 37vw;
  height: 29vh;
  object-fit: cover;
  object-position: top;
}





// animations

.hiddenKeyVisual {
  @include animationMixin(hiddenKeyVisual, 1.6s)
}

@keyframes hiddenKeyVisual {
  from {
    opacity: 1;
  }
  to {
    opacity: 0; 
  }
}

.showKeyVisual {
  @include animationMixin(showKeyVisual, 2s)
}

@keyframes showKeyVisual {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenKeyVisualTitle {
  @include animationMixin(hiddenKeyVisualTitle, 1.8s)
}

@keyframes hiddenKeyVisualTitle {
  from {
    top: 20%;
  }
  to {
    top: -80%;
  }
}

.showKeyVisualTitle {
  @include animationMixin(showKeyVisualTitle, 1.8s)
}

@keyframes showKeyVisualTitle {
  from {
    top: -80%;
  }
  to {
    top: 20%;
  }
}
