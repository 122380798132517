@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.content-animations-tablet {
  position: relative;
}

.content-animations-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.content-graphics-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 850px;
  margin: 0 auto;
  gap: 12px;
  position: relative;
  height: 100%;
  padding: 0 30px;
  
  @include Phone {
    padding: 0 16px;
  }
}

.content-animations-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}

.content-animations-tablet__watch-all {
  background-color: $yellow;
  padding: 17px;
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: $black2;
  outline: none;
  border: none;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include MiniTablet {
    font-size: 16px;
    padding: 12px 17px;
  }
}

.content-animation__watch-all-arrow {
  margin-right: 16px;
}