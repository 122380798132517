@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.creation-gifts {
  display: flex;
  justify-content: center;
}

.creation-gifts-slide {
  width: 100vw;
  height: 54vh;
  overflow: hidden;
}

.creation-packs__title {
  top: 23%;
}

.creation-gifts__wrapper {
  height: 55vh;
}

.creation-gifts {
  display: flex;

  &__wrapper {
    .swiper-button-next, 
    .swiper-button-prev {
      top: 43%;
    }
  }

  &__right {
    &-top {
      display: flex;

      img {
        height: 26vh;
        width: 25vw;
      }

      &-block {
        width: 18.5vw;
        height: 26vh;
        background: #FDB813;
        padding: 0 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
          font-weight: 400;
          font-size: 2.6vh;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
        }
      }
    }
  }
}

.creation-gifts__left {
  &-top {
    display: flex;

    img {
      &:first-child {
        width: 13vw;
        height: 26vh;
      }
    }
  }

  &-top-block {
    width: 29.5vw;
    height: 26vh;
    background: #FDB813;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 2.6vh;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
    }
  }

  &-bottom {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &-img {
      width: 100%;
      height: 26vh;
      object-fit: cover;
    }
  }
}

.creation-gifts__right-bottom {
  display: flex;
  align-items: center;
  height: 26vh;
  width: 43vw;

  img {
    height: 26vh;
  }

  &-text {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 2.8vh;
    text-align: center;
    color: $black;
    max-width: 408px;
    margin-left: 10%;
  }
}


// animations 


.hiddenCreationGifts {
  @include animationMixin(hiddenCreationGifts, 1.6s)
}

@keyframes hiddenCreationGifts {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showCreationGifts {
  @include animationMixin(showCreationGifts, 2s)
}

@keyframes showCreationGifts {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenCreationGiftsTitle {
  @include animationMixin(hiddenCreationGiftsTitle, 1.8s)
}

@keyframes hiddenCreationGiftsTitle {
  from {
    top: 23%;
  }
  to {
    top: -77%;
  }
}

.showCreationGiftsTitle {
  @include animationMixin(showCreationGiftsTitle, 1.8s)
}

@keyframes showCreationGiftsTitle {
  from {
    top: -77%;
  }
  to {
    top: 23%;
  }
}
