@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.video-production-tablet {
  position: relative;
}

.video-production-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.video-production-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 850px;
  margin: 0 auto;
  gap: 12px;
  position: relative;
  height: 100%;
  padding: 0 30px;
  
  @include Phone {
    padding: 0 16px;
  }
}

.video-production-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}

.video-production-tablet__watch-all {
  background-color: $yellow;
  padding: 17px;
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: $black2;
  outline: none;
  border: none;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include MiniTablet {
    font-size: 16px;
    padding: 10px 17px;
    max-width: 200px;
  }
}

.content-animation__watch-all-arrow {
  margin-right: 16px;
}

.video-prod__wrapper-img-link {
  cursor: pointer;
  position: relative;

  &:hover {
    svg {
      path {
        fill: $yellow;
      }
    }
  }
}

.video-prod__play-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  @include xs {
    width: 30px;
  }
}

.video-prod__wrapper-play-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  background-color: $white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    path {
      transition: .4s;
    }
  }

  @include xs {
    width: 60px;
    height: 60px;
  }
}
