@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.swiper-exhibition-slide {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
}

.swiper-exhibition__main {
  .main-swiper {
    .swiper-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

  }
}

.swiper-exhibition {
  display: flex;
  justify-content: center;
  align-items: center;

  &__left {
    width: 48vw;
    height: 60vh;
    object-fit: cover;
  }

  &__right {
    display: flex;
    flex-direction: column;
  
    img {
      width: 37vw;
      height: 30vh;
      object-fit: cover;

      &:nth-child(2) {
        // object-position: bottom;
      }
    }
  }
}

.exhibition__title {
  max-width: 1320px;
  width: 100%;
  top: 21%;
  font-size: 3.8rem;
  line-height: 1.1;

  .swiper-main__ampersand {
    font-size: 3.8rem;
    line-height: 1.1;
  }
}

.swiper-exhibition-slide {
  height: 57vh;
}

.exhibition__wrapper {
  bottom: 3.2%;
}

.exhibition__wrapper {
  .swiper-button-prev,
  .swiper-button-next {
    top: 43.5%;
  }
}


// animations

.hiddenExhibition {
  @include animationMixin(hiddenExhibition, 1.6s)
}

@keyframes hiddenExhibition {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showExhibition {
  @include animationMixin(showExhibition, 2s)
}

@keyframes showExhibition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenExhibitionTitle {
  @include animationMixin(hiddenExhibitionTitle, 1.8s)
}

@keyframes hiddenExhibitionTitle {
  from {
    top: 21%;
  }
  to {
    top: -79%;
  }
}

.showExhibitionTitle {
  @include animationMixin(showExhibitionTitle, 1.8s)
}

@keyframes showExhibitionTitle {
  from {
    top: -79%;
  }
  to {
    top: 21%;
  }
}
