@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.printing-design-slide {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
}

.printing-design {
  display: flex;
  gap: 15px;

  &__block {
    display: flex;

    img {
      width: 25vw;
      height: 63vh;
    }

    &-title {
      font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 40px;
      color: $black2;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);

      @include md {
        font-size: 1.3rem;
      }
    }
  }

  &__title {
    width: 100%;
    font-size: 4rem;

    @include md {
      font-size: 3rem;
    }
  }
}

.priting-design__wrapper {
  width: 100%;
  position: fixed;
  bottom: 6.48%;
  display: none;

  .swiper-wrapper {
    display: flex;
    gap: 3px;
  }

  .swiper-button-next {
    top: 55%;
  }

  .swiper-button-prev {
    top: 55%;
  }
}

.priting-design__block-wrapp {
  background-color: $lightGray2;
  height: 280px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  border-right: 7px solid $yellow;

  @include md {
    padding: 0 10px;
    border-right: 4px solid $yellow;
  }
}


// animations


.hiddenPrintingDesign {
  @include animationMixin(hiddenPrintingDesign, 1.6s)
}
  
@keyframes hiddenPrintingDesign {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showPrintingDesign {
  @include animationMixin(showPrintingDesign, 2s)
}

@keyframes showPrintingDesign {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenPrintingDesignTitle {
  @include animationMixin(hiddenPrintingDesignTitle, 1.8s)
}

@keyframes hiddenPrintingDesignTitle {
  from {
    top: 18.5%;
  }
  to {
    top: -81.5%;
  }
}

.showPrintingDesignTitle {
  @include animationMixin(showPrintingDesignTitle, 1.8s)
}

@keyframes showPrintingDesignTitle {
  from {
    top: -81.5%;
  }
  to {
    top: 18.5%;
  }
}
