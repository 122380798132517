@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.concept-dev__rectangles {
  position: fixed;
  right: 3%;
  top: -15.5%;
  width: 360px;
  height: 360px;
  background: $yellow;
  transform: rotate(45deg);
  z-index: 2;
  display: none;

  @include md {
    width: 306px;
    height: 306px;
  }
}

.concept-dev__rectangle-gradient {
  width: 322px;
  height: 24px;
  position: absolute;
  right: 40%;
  top: 110%;
  background: linear-gradient(270deg, #FDB813 -0.03%, rgba(253, 184, 19, 0) 102.2%);
  transform: rotate(0deg);
}

.concept-dev__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  right: 20%;
  top: 114%;
}


.concept-dev__border-gray {
  position: fixed;
  width: 33vw;
  height: 33vw;
  opacity: 0.4;
  border: 160px solid #ACACAD;
  transform: rotate(45deg);
  right: 20%;
  top: -19%;
  z-index: 1;
  display: none;
}

.concept-dev__border-yellow {
  position: fixed;
  top: 14.26%;
  left: 2.14%;
  display: none;
  height: 70vh;
}

.concept-dev__title {
  position: fixed;
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 5rem;
  line-height: 1.3;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  bottom: 16%;
  left: 26.25%;
  z-index: 2;
  color: $white;
  display: none;

  &-ampersand {
      color: $yellow;
  }
}

.concept-dev__play-arrows {
  position: fixed;
  top: 42.8%;
  right: 16.72%;
  display: none;
}

.concept-dev__scretch {
  position: fixed;
  bottom: 0.9%;
  right: 11%;
  z-index: 1;
  display: none;
}

.concept-dev__logo {
  position: fixed;
  right: 4%;
  bottom: 4%;
  display: none;
}

.concept-dev__rectangles-bottom {
  display: none;
  bottom: 11%;
  left: -2.5%;
  position: fixed;
}


.concept-dev__rectangle-gradient-bottom {
  width: 322px;
  height: 24px;
  position: absolute;
  left: 0%;
  bottom: 11%;
  background: linear-gradient(270deg, #FDB813 -0.03%, rgba(253, 184, 19, 0) 102.2%);
  transform: rotate(45deg);
}

.concept-dev__rectangle-border-bottom {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  left: -1.5%;
  bottom: -55%;
}



// animations 



// title animation

.hiddenTitleConceptDev {
  @include animationMixin(hiddenTitleConceptDev, 2s)
}
  
.showTitleConceptDev {
  @include animationMixin(showTitleConceptDev, 2s)
}
  
@keyframes hiddenTitleConceptDev {
  from {
    bottom: 16%;
  }
  to {
    bottom: 116%;
  }
}

@keyframes showTitleConceptDev {
  from {
    bottom: 116%;
  }
  to {
    bottom: 16%;
  }
}


.transformToTopTitleConceptDev {
  @include animationMixin(transformToTopTitleConceptDev, 2s)
}

.transformToDownTitleConceptDev {
  @include animationMixin(transformToDownTitleConceptDev, 2s)
}

@keyframes transformToTopTitleConceptDev {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1750px);
  }
}

@keyframes transformToDownTitleConceptDev {
  from {
    transform: translateX(-999px);
  }
  to {
    transform: translateX(0);
  }
}


  
// top rectangles animation

.hiddenTopRectanglesConceptDev {
  @include animationMixin(hiddenTopRectanglesConceptDev, 2s)
}

@keyframes hiddenTopRectanglesConceptDev {
  from {
    top: -15.5%;
  }
  to {
    top: -84.5%;
  }
}
  
.showTopRectanglesConceptDev {
  @include animationMixin(showTopRectanglesConceptDev, 2s)
}

@keyframes showTopRectanglesConceptDev {
  from {
    top: -115.5%;
  }
  to {
    top: -15.5%;
  }
}

.transformToTopRectanglesTopConceptDev {
  @include animationMixin(transformToTopRectanglesTopConceptDev, 2s)
}

.transformToDownRectanglesTopConceptDev {
  @include animationMixin(transformToDownRectanglesTopConceptDev, 2s)
}

@keyframes transformToTopRectanglesTopConceptDev {
  from {
    top: -15.5%;
  }
  to {
    top: -115.5%;
  }
}

@keyframes transformToDownRectanglesTopConceptDev {
  from {
    top: -115.5%;
  }
  to {
    top: -15.5%;
  }
}


// border gray animation

.hiddenBorderGrayConceptDev {
  @include animationMixin(hiddenBorderGrayConceptDev, 2s)
}
  
@keyframes hiddenBorderGrayConceptDev {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}

.showBorderGrayConceptDev {
  @include animationMixin(showBorderGrayConceptDev, 2s)
}

@keyframes showBorderGrayConceptDev {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}


.transformToTopBorderGrayConceptDev {
  @include animationMixin(transformToTopBorderGrayConceptDev, 2s)
}

.transformToDownBorderGrayConceptDev {
  @include animationMixin(transformToDownBorderGrayConceptDev, 2s)
}

@keyframes transformToTopBorderGrayConceptDev {
  from {
    top: -19%;
  }
  to {
    top: -119%;
  }
}

@keyframes transformToDownBorderGrayConceptDev {
  from {
    top: -119%;
  }
  to {
    top: -19%;
  }
}


// scretch animation


.hiddenScretchConceptDev {
  @include animationMixin(hiddenScretchConceptDev, 2s)
}

@keyframes hiddenScretchConceptDev {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showScretchConceptDev {
  @include animationMixin(showScretchConceptDev, 2s)
}

@keyframes showScretchConceptDev {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopScretchConceptDev {
  @include animationMixin(transformToTopScretchConceptDev, 2s)
}

.transformToDownScretchConceptDev {
  @include animationMixin(transformToDownScretchConceptDev, 2s)
}

@keyframes transformToTopScretchConceptDev {
  from {
    bottom: 0.9%;
  }
  to {
    bottom: 100.9%;
  }
}

@keyframes transformToDownScretchConceptDev {
  from {
    bottom: 100.9%;
  }
  to {
    bottom: 0.9%;
  }
}


// play arrows animation


.hiddenPlayArrowsConceptDev {
  @include animationMixin(hiddenPlayArrowsConceptDev, 2s)
}
  
@keyframes hiddenPlayArrowsConceptDev {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showPlayArrowsConceptDev {
  @include animationMixin(showPlayArrowsConceptDev, 2s)
}
  
@keyframes showPlayArrowsConceptDev {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopPlayArrowsConceptDev {
  @include animationMixin(transformToTopPlayArrowsConceptDev, 2s)
}

.transformToDownPlayArrowsConceptDev {
  @include animationMixin(transformToDownPlayArrowsConceptDev, 2s)
}

@keyframes transformToTopPlayArrowsConceptDev {
  from {
    top: 42.8%;
  }
  to {
    top: -57.2%;
  }
}

@keyframes transformToDownPlayArrowsConceptDev {
  from {
    top: -57.2%;
  }
  to {
    top: 42.8%;
  }
}

// rectangles bottom animation

.hiddenRectanglesBottomConceptDev {
  @include animationMixin(hiddenRectanglesBottomConceptDev, 2s)
}
  
@keyframes hiddenRectanglesBottomConceptDev {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showRectanglesBottomConceptDev {
  @include animationMixin(showRectanglesBottomConceptDev, 2s)
}
  
@keyframes showRectanglesBottomConceptDev {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.transformToTopRectanglesBottomConceptDev {
  @include animationMixin(transformToTopRectanglesBottomConceptDev, 2s)
}

.transformToDownRectanglesBottomConceptDev {
  @include animationMixin(transformToDownRectanglesBottomConceptDev, 2s)
}

@keyframes transformToTopRectanglesBottomConceptDev {
  from {
    bottom: 11%;
  }
  to {
    bottom: 111%;
  }
}

@keyframes transformToDownRectanglesBottomConceptDev {
  from {
    bottom: 111%;
  }
  to {
    bottom: 11%;
  }
}


//logo footer animation


.hiddenFooterLogoConceptDev {
  @include animationMixin(hiddenFooterLogoConceptDev, 2s)
}

@keyframes hiddenFooterLogoConceptDev {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showFooterLogoConceptDev {
  @include animationMixin(showFooterLogoConceptDev, 2s)
}

@keyframes showFooterLogoConceptDev {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.transformToTopLogoFooterConceptDev {
  @include animationMixin(transformToTopLogoFooterConceptDev, 2s)
}

.transformToDownLogoFooterConceptDev {
  @include animationMixin(transformToDownLogoFooterConceptDev, 2s)
}

@keyframes transformToTopLogoFooterConceptDev {
  from {
    bottom: 4%;
  }
  to {
    bottom: 104%;
  }
}

@keyframes transformToDownLogoFooterConceptDev {
  from {
    bottom: 104%;
  }
  to {
    bottom: 4%;
  }
}


// border yellow animation

.hiddenBorderYellowConceptDev {
  @include animationMixin(hiddenBorderYellowConceptDev, 2s)
}

@keyframes hiddenBorderYellowConceptDev {
  from {
    left: 2.14%;
  }
  to {
    left: 102.14%;
  }
}
  
.showBorderYellowConceptDev {
  @include animationMixin(showBorderYellowConceptDev, 2s)
}

@keyframes showBorderYellowConceptDev {
  from {
    left: 102.14%;
  }
  to {
    left: 2.14%;
  }
}

.transformToTopBorderYellowConceptDev {
  @include animationMixin(transformToTopBorderYellowConceptDev, 2s)
}

.transformToDownBorderYellowConceptDev {
  @include animationMixin(transformToDownBorderYellowConceptDev, 2s)
}

@keyframes transformToTopBorderYellowConceptDev {
  from {
    top: 14.26%;
  }
  to {
    top: -85.74%;
  }
}

@keyframes transformToDownBorderYellowConceptDev {
  from {
    top: -85.74%;
  }
  to {
    top: 14.26%;
  }
}


// dots animation

.hiddenDotsConceptDev {
  @include animationMixin(hiddenDotsConceptDev, 2s)
}

@keyframes hiddenDotsConceptDev {
  from {
    left: 31.6%;
  }
  to {
    left: 131.6%;
  }
}

.showDotsConceptDev {
  @include animationMixin(showDotsConceptDev, 2s)
}

@keyframes showDotsConceptDev {
  from {
    left: 131.6%;
  }
  to {
    left: 31.6%;
  }
}
  
.transformToTopDotsConceptDev {
  @include animationMixin(transformToTopDotsConceptDev, 2s)
}

.transformToDownDotsConceptDev {
  @include animationMixin(transformToDownDotsConceptDev, 2s)
}

@keyframes transformToTopDotsConceptDev {
  from {
    top: 26%;
  }
  to {
    top: -74%;
  }
}

@keyframes transformToDownDotsConceptDev {
  from {
    top: -74%;
  }
  to {
    top: 26%;
  }
}


// strokes animation

.hiddenStrokesConceptDev {
  @include animationMixin(hiddenStrokesConceptDev, 2s)
}

@keyframes hiddenStrokesConceptDev {
  from {
    left: 27.2%;
  }
  to {
    left: 127.2%;
  }
}

.showStrokesConceptDev {
  @include animationMixin(showStrokesConceptDev, 2s)
}

@keyframes showStrokesConceptDev {
  from {
    left: 127.2%;
  }
  to {
    left: 27.2%;
  }
}

.transformToTopStrokesConceptDev {
  @include animationMixin(transformToTopStrokesConceptDev, 2s)
}

.transformToDownStrokesConceptDev {
  @include animationMixin(transformToDownStrokesConceptDev, 2s)
}

@keyframes transformToTopStrokesConceptDev {
  from {
    top: 47%;
  }
  to {
    top: -53%;
  }
}

@keyframes transformToDownStrokesConceptDev {
  from {
    top: -53%;
  }
  to {
    top: 47%;
  }
}


// waves animation


.hiddenWavesConceptDev {
  @include animationMixin(hiddenWavesConceptDev, 2s)
}

@keyframes hiddenWavesConceptDev {
  from {
    left: 22.4%;
  }
  to {
    left: 122.4%;
  }
}

.showWavesConceptDev {
  @include animationMixin(showWavesConceptDev, 2s)
}

@keyframes showWavesConceptDev {
  from {
    left: 122.4%;
  }
  to {
    left: 22.4%;
  }
}

.transformToTopWavesConceptDev {
  @include animationMixin(transformToTopWavesConceptDev, 2s)
}

.transformToDownWavesConceptDev {
  @include animationMixin(transformToDownWavesConceptDev, 2s)
}

@keyframes transformToTopWavesConceptDev {
  from {
    top: 36.6%;
  }
  to {
    top: -63.4%;
  }
}

@keyframes transformToDownWavesConceptDev {
  from {
    top: -63.4%;
  }
  to {
    top: 36.6%;
  }
}
