@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.our-clients__title {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 3.43%;
  width: 100%;
  display: none;
  
  &-text {
    font-family: 'FavoritC-Bold', 'Inter', sans-serif;
    font-weight: 700;
    font-size: 8.15vh;
    line-height: 88px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $white;
    margin-top: 34px;  
    
    @include md {
      margin-top: 15px;
    }
    @include xs {
      font-size: 4rem;
    }
    @include Tablet {
      font-size: 3.25rem;
      padding-top: 16px;
    }
    @include Phone {
      font-size: 2.25rem;
    }
  }
}

.our-clients__rect-group {
  max-width: 255px;
  width: 100%;
  height: 18vh;

  @include xs {
    width: 180px;
  }
  @include Tablet {
    width: 120px;
  }
  @include Phone {
    width: 93px;
    height: 72px;
  }
}

.our-clients__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  // max-width: 1317px;
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: -17.3%;
  height: 50%;
  gap: 10px;
  justify-content: space-between;
  display: none;
  padding: 0 30px;

  &-worldwide {
    font-family: 'FavoritHeavyC', 'Inter', sans-serif;
    font-weight: 900;
    font-size: 3vh;
    color: $black;
    margin-bottom: 10px;
    position: relative;

    &::before {
      content: "";
      width: 45%;
      height: 5px;
      background: $yellow;
      position: absolute;
      bottom: -20%;
      right: 0;
    }
  }

  &-block {
    &-world {
      display: flex;
      align-items: center;

      img {
        height: 5vh;
        position: relative;
        z-index: 2;
        margin: 0 25px;
      }
    }
    &-ukraine {
      display: flex;
      align-items: center;
      max-width: 1080px;
      width: 100%;
      justify-content: space-around;

      img {
        height: 12vh;
        position: relative;
        z-index: 2;
        margin: 0 10px;
      }
    }
    &-general {
      display: flex;
      justify-content: space-around;
      max-width: 1317px;
      width: 100%;

      img {
        height: 8.5vh;
        position: relative;
        z-index: 2;
        margin: 0 10px;
      }
    }
  }

  &-ukraine {
    font-family: 'FavoritHeavyC', 'Inter', sans-serif;
    font-weight: 900;
    font-size: 3vh;
    color: $black;
    position: relative;

    &::before {
      content: "";
      width: 45%;
      height: 5px;
      background: $yellow;
      position: absolute;
      bottom: -20%;
      right: 0;
    }
  }
}

.our-clients__room8group {
  width: 10vw;
  height: 9vh !important;
}
.our-clients__game-world{
  height: 9vh !important;
}
.our-clients__darnytsia {
  height: 15vh !important;

  @include xs {
    height: 10vh !important;
  }
}

// animations

.hiddenOurClientTitle {
  @include animationMixin(hiddenOurClientTitle, 1.8s)
}

.showOurClientTitle {
  @include animationMixin(showOurClientTitle, 1.8s)
}

@keyframes hiddenOurClientTitle {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-500px);
    opacity: 0;
  }
}

@keyframes showOurClientTitle {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(0);
  }
}


.showOurClientsContent {
  @include animationMixin(showOurClientsContent, 1.8s)
}

.hiddenOurClientsContent {
  @include animationMixin(hiddenOurClientsContent, 1.2s)
}

@keyframes hiddenOurClientsContent {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showOurClientsContent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
