@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.logo-identity-slide {
  width: 100vw;
  height: 58vh;
  overflow: hidden;
}

.logo-identity-slide-second {
  width: 100vw;
  height: 58vh;
  overflow: hidden;
}


.logo-identity {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__wrapper {

    .swiper-slide {
      display: flex;
      justify-content: center;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 43.5%;
    }
  }
}

.logo-identity-second__left-top-block {
  height: 31vh;
  width: 60%;
  background-color: $yellow;
}

.logo-identity-second__left-top-block-text {
  font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
  color: #2B2B2B;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 0 72px;
}

.logo-identity__block {
  display: flex;

  &-center {
    display: flex;
    flex-direction: column;
  }
}

.logo-identity-second__right-bottom {
  width: 32vw;
  height: 28vh;
  background-color: #F2F2F2;
  position: relative;
}

.logo-identity-second__right-bottom-text {
  font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.2vh;
  color: #2B2B2B;
  max-width: 350px;
  position: absolute;
  left: 10%;
  top: 35%;
}


.logo-identity-second__left {
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;

    img {
      height: 31vh;
      width: 40%;

      &:nth-child(2) {
        width: 60%;
      }
    }
  }

  &-bottom {
    width: 100%;
    height: 28vh;
    object-fit: cover;
  }
}

.logo-identity-second__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    &:nth-child(1) {
      width: 32vw;
      height: 31vh;
      object-fit: cover;
    }
  }
}


// animations


.hiddenLogoIdentity {
  @include animationMixin(hiddenLogoIdentity, 2s)
}

@keyframes hiddenLogoIdentity {
  from {
    bottom: 8.2%;
  }
  to {
    bottom: -91.8%;
  }
}

.showLogoIdentity {
  @include animationMixin(showLogoIdentity, 2s)
}

@keyframes showLogoIdentity {
  from {
    bottom: -91.8%;
  }
  to {
    bottom: 8.2%;
  }
}

.transformToDownLogoIdentity {
  @include animationMixin(transformToDownLogoIdentity, 1.6s)
}

.transformToTopLogoIdentity {
  @include animationMixin(transformToTopLogoIdentity, 1.6s)
}

@keyframes transformToTopLogoIdentity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes transformToDownLogoIdentity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




.hiddenLogoIdentityTitle {
  @include animationMixin(hiddenLogoIdentityTitle, 2s)
}

@keyframes hiddenLogoIdentityTitle {
  from {
    top: 18.5%;
  }
  to {
    top: 118.5%;
  }
}

.showLogoIdentityTitle {
  @include animationMixin(showLogoIdentityTitle, 2s)
}

@keyframes showLogoIdentityTitle {
  from {
    top: 118.5%;
  }
  to {
    top: 18.5%;
  }
}


.transformToDownLogoIdentityTitle {
  @include animationMixin(transformToDownLogoIdentityTitle, 1.6s)
}

.transformToTopLogoIdentityTitle {
  @include animationMixin(transformToTopLogoIdentityTitle, 1.6s)
}

@keyframes transformToTopLogoIdentityTitle {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes transformToDownLogoIdentityTitle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
