@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.team-tablet {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 5% 30px;
    justify-items: center;

    @include MiniTablet {
      padding: 5% 16px;
      gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
