@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.what-doing-list {
  max-width: 802px;
  width: 100%;
  background: $lightGray2;
  display: flex;
  align-items: center;

  @include xs {
    max-width: 100%;
  }

  &__img {
    height: 16vh;
    max-width: 170px;
    width: 100%;
    object-fit: cover;

    @include mdd {
      height: 13vh;
      max-width: 117px;
    }

    @include xs {
      width: auto;
      height: 12vh;
    }
    @include Tablet {
      height: 10vh;
    }
    @include Phone {
      max-width: 80px;
      width: 100%;
      height: 8vh;
    }
  }

  &__wrapp {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }

  &__title {
    font-weight: 400;
    font-size: 2.5vh;
    color: $black2;
    position: relative;
    font-family: "FavoritC", 'Source Sans Pro', sans-serif;
    max-width: 330px;
    @include xs {
      font-size: 2vh; 
    }

    @include Phone {
      font-size: 14px;
      line-height: 20px;
    }

    &::after {
      content: '';
      display: block;
      background-color: $yellow;
      width: 40px;
      height: 3px;
      position: absolute;
      left: 0;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.9vh;
    line-height: 28px;
    color: $black2;
    margin-top: 10px;
    font-family: "FavoritLightC", 'Source Sans Pro', sans-serif;

    @include Tablet {
      font-size: 1.8vh;
    }
    @include Phone {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
