@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.content-graphics__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.1;
  letter-spacing: 0.1em;
  color: $black2;
  position: fixed;
  top: 23%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: none;
  text-align: center;

  max-width: 1300px;
  width: 100%;
  will-change: opacity, top, transform;

  @include mdd {
    font-size: 4rem;
  }
  @include md {
    font-size: 3rem;
    max-width: 1170px;
  }
}

.content-graphics__content {
  max-width: 1777px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.content-graphics__wrapper {
  width: 100%;
  position: fixed;
  bottom: 16.4%;
  gap: 32px;
  align-items: center;
  display: none;
  justify-content: center;

  &-img {
    height: 46vh;
    width: 45.2vw;
  }
}

.content-graphics__button-arrow {
  @include mdd {
    width: 55px;
  }
  @include md {
    width: 45px;
  }
}

.content-graphics__button-watch {
  outline: none;
  border: none;
  background-color: $yellow;
  max-width: 322px;
  width: 100%;
  display: none;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  gap: 15px;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.4vh;
  color: $black2;

  position: fixed;
  bottom: 4%;
  right: 3.85%;
  cursor: pointer;

  @include mdd {
    // font-size: 1.5rem;
    max-width: 280px;
  }
}

.content-graphics__button-text {
  text-align: left;
  min-width: 165px;
  width: min-content;
}


// animations

.hidden3dGraphics {
  @include animationMixin(hidden3dGraphics, 2s)
}
  
@keyframes hidden3dGraphics {
  from {
    bottom: 16.4%;
  }
  to {
    bottom: -83.6%;
  }
}

.show3dGraphics {
  @include animationMixin(show3dGraphics, 2s)
}

@keyframes show3dGraphics {
  from {
    bottom: -83.6%;
  }
  to {
    bottom: 16.4%;
  }
}
  
  
.transformToTop3dGraphics {
  @include animationMixin(transformToTop3dGraphics, 2s)
}

@keyframes transformToTop3dGraphics {
  from {
    bottom: 16.4%;
  }
  to {
    bottom: 116.4%;
  }
}
  
.transformToDown3dGraphics {
  @include animationMixin(transformToDown3dGraphics, 2s)
}

@keyframes transformToDown3dGraphics {
  from {
    bottom: 116.4%;
  }
  to {
    bottom: 16.4%;
  }
}

  
.hidden3dGraphicsTitle {
  @include animationMixin(hidden3dGraphicsTitle, 2s)
}

@keyframes hidden3dGraphicsTitle {
  from {
    top: 23%;
  }
  to {
    top: 123%;
  }
}

.show3dGraphicsTitle {
  @include animationMixin(show3dGraphicsTitle, 2s)
}

@keyframes show3dGraphicsTitle {
  from {
    top: 123%;
  }
  to {
    top: 23%;
  }
}

  
.transformToTop3dGraphicsTitle {
  @include animationMixin(transformToTop3dGraphicsTitle, 2s)
}

@keyframes transformToTop3dGraphicsTitle {
  from {
    top: 23%;
  }
  to {
    top: -77%;
  }
}

.transformToDown3dGraphicsTitle {
  @include animationMixin(transformToDown3dGraphicsTitle, 2s)
}

@keyframes transformToDown3dGraphicsTitle {
  from {
    top: -77%;
  }
  to {
    top: 23%;
  }
}



.hidden3dGraphicsButton {
  @include animationMixin(hidden3dGraphicsButton, 2s)
}

@keyframes hidden3dGraphicsButton {
  from {
    bottom: 4%;
  }
  to {
    bottom: -104%;
  }
}

.show3dGraphicsButton {
  @include animationMixin(show3dGraphicsButton, 2s)
}

@keyframes show3dGraphicsButton {
  from {
    bottom: -104%;
  }
  to {
    bottom: 4%;
  }
}
  
  
.transformToTop3dGraphicsButton {
  @include animationMixin(transformToTop3dGraphicsButton, 2s)
}

@keyframes transformToTop3dGraphicsButton {
  from {
    bottom: 4%;
  }
  to {
    bottom: 104%;
  }
}

.transformToDown3dGraphicsButton {
  @include animationMixin(transformToDown3dGraphicsButton, 2s)
}

@keyframes transformToDown3dGraphicsButton {
  from {
    bottom: 104%;
  }
  to {
    bottom: 4%;
  }
}
