@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.queen-mary__rombh-border {
  position: fixed;
  width: 433.95px;
  height: 433.95px;
  border: 100px solid $yellow;
  transform: rotate(45deg);
  right: 4.7%;
  top: -7%;
  display: none;
}

.queen-mary__title {
  position: fixed;
  flex-direction: column;
  width: 35%;
  left: 3.75%;
  bottom: 16.5%;
  display: none;

  &-top {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 6vh;
    line-height: 1.1;
    color: $black2;

    @include md {
      font-size: 3rem;
    }
  }
  &-main {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 6vh;
    line-height: 1;
    color: $black2;
    margin-top: 16px;
    white-space: nowrap;

    @include md {
      font-size: 3rem;
    }
  }
}

.queen-mary__text {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 38px;
  color: $black2;
  display: flex;
  flex-direction: column;
  margin-top: 55px;

  @include mdd {
    font-size: 1.6rem;
    line-height: 27px;
  }
  @include md {
    font-size: 1.48rem;
    margin-top: 25px;
  }
}

.queen-mary__document {
  position: fixed;
  bottom: 9.7%;
  right: 34.22%;
  z-index: 2;
  display: none;
  width: 23vw;
}

.queen-mary__laptop {
  position: fixed;
  right: 2.14%;
  top: 19.4%;
  z-index: 1;
  display: none;
  width: 43vw;
}

.queen-mary__dots {
  position: fixed;
  right: 1.8%;
  bottom: 32.6%;
  display: none;
}

.queen-mary__strokes {
  position: fixed;
  right: 9.3%;
  bottom: 24.5%;
  display: none;
}

.queen-mary__waves {
  position: fixed;
  right: 2.14%;
  top: 2.3%;   
  display: none;
}


// animations


.hiddenRombhBorderQueenMary {
  @include animationMixin(hiddenRombhBorderQueenMary, 1.6s)
}

.showRombhBorderQueenMary {
  @include animationMixin(showRombhBorderQueenMary, 1.8s)
}

@keyframes hiddenRombhBorderQueenMary {
  from {
    opacity: 1;
    top: -7%;
  }
  to {
    opacity: 1;
    top: -93%;
  }
}


@keyframes showRombhBorderQueenMary {
  from {
    opacity: 1;
    top: -93%;
  }
  to {
    opacity: 1;
    top: -7%;
  }
}


.hiddenWavesQueenMary {
  @include animationMixin(hiddenWavesQueenMary, 1.6s)
}

.showWavesQueenMary {
  @include animationMixin(showWavesQueenMary, 1.8s)
}

@keyframes hiddenWavesQueenMary {
  from {
    opacity: 1;
    top: 2.3%;
  }
  to {
    opacity: 1;
    top: -97.7%;
  }
}

@keyframes showWavesQueenMary {
  from {
    opacity: 1;
    top: -97.7%;
  }
  to {
    opacity: 1;
    top: 2.3%;
  }
}


.hiddenDescrQueenMary {
  @include animationMixin(hiddenDescrQueenMary, 1.6s)
}

.showDescrQueenMary {
  @include animationMixin(showDescrQueenMary, 1.8s)
}

@keyframes hiddenDescrQueenMary {
  from {
    bottom: 16.5%;
  }
  to {
    bottom: -83.5%;
  }
}

@keyframes showDescrQueenMary {
  from {
    bottom: -83.5%;
  }
  to {
    bottom: 16.5%;
  }
}


.hiddenDocumentQueenMary {
  @include animationMixin(hiddenDocumentQueenMary, 1.6s)
}

.showDocumentQueenMary {
  @include animationMixin(showDocumentQueenMary, 1.8s)
}

@keyframes hiddenDocumentQueenMary {
  from {
    bottom: 9.7%;
  }
  to {
    bottom: -90.3%;
  }
}

@keyframes showDocumentQueenMary {
  from {
    bottom: -90.3%;
  }
  to {
    bottom: 9.7%;
  }
}



.hiddenDotsQueenMary {
  @include animationMixin(hiddenDotsQueenMary, 1.6s)
}

.showDotsQueenMary {
  @include animationMixin(showDotsQueenMary, 1.8s)
}

@keyframes hiddenDotsQueenMary {
  from {
    bottom: 32.6%;
  }
  to {
    bottom: -67.4%;
  }
}

@keyframes showDotsQueenMary {
  from {
    bottom: -67.4%;
  }
  to {
    bottom: 32.6%;
  }
}


.hiddenStrokesQueenMary {
  @include animationMixin(hiddenStrokesQueenMary, 1.6s)
}

.showStrokesQueenMary {
  @include animationMixin(showStrokesQueenMary, 1.8s)
}

@keyframes hiddenStrokesQueenMary {
  from {
    bottom: 24.5%;
  }
  to {
    bottom: -75.5%;
  }
}

@keyframes showStrokesQueenMary {
  from {
    bottom: -75.5%;
  }
  to {
    bottom: 24.5%;
  }
}



.hiddenLaptopQueenMary {
  @include animationMixin(hiddenLaptopQueenMary, 1.6s)
}

.showLaptopQueenMary {
  @include animationMixin(showLaptopQueenMary, 1.8s)
}

@keyframes hiddenLaptopQueenMary {
  from {
    top: 19.4%;
    transform: translateX(0) rotate(0);
  }
  to {
    top: -80.6%;
    transform: translateX(-50%) rotate(-45deg);
  }
}

@keyframes showLaptopQueenMary {
  from {
    top: -80.6%;
    transform: translateX(-50%) rotate(-45deg);
  }
  to {
    top: 19.4%;
    transform: translateX(0) rotate(0);
  }
}

.transformToTopQueenMaryLaptop {
  @include animationMixin(transformToTopQueenMaryLaptop, 2s)
}

.transformToDownQueenMaryLaptop {
  @include animationMixin(transformToDownQueenMaryLaptop, 1.8s)
}

@keyframes transformToTopQueenMaryLaptop {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(400%);
  }
}

@keyframes transformToDownQueenMaryLaptop {
  from {
    transform: translateX(400%);
  }
  to {
    transform: translateX(0%);
  }
}

.transformToTopQueenMaryDies {
  @include animationMixin(transformToTopQueenMaryDies, 2s)
}

.transformToDownQueenMaryDies {
  @include animationMixin(transformToDownQueenMaryDies, 2s)
}

@keyframes transformToTopQueenMaryDies {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes transformToDownQueenMaryDies {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
