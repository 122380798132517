@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.video-prod__title {
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: $black2;
  position: fixed;
  top: 23.7%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: none;
  text-align: center;

  max-width: 1757px;
  width: 100%;
  will-change: opacity, top, transform;

  @include mdd {
    font-size: 4rem;
    max-width: 1320px;
  }
  @include md {
    font-size: 3rem;
  }
}

.video-prod__content {
  max-width: 1777px;
  width: 100%;
  overflow: hidden;
}

.video-prod__wrapper {
  width: 100%;
  position: fixed;
  bottom: 16.4%;
  gap: 32px;
  align-items: center;
  display: none;
  justify-content: center;

  &-img {
    height: 46vh;
    width: 45.2vw;
  }
}

.video-prod__button-arrow {
  @include mdd {
    width: 55px;
  }
  @include md {
    width: 45px;
  }
}

.video-prod__button-watch {
  outline: none;
  border: none;
  background-color: $yellow;
  max-width: 322px;
  width: 100%;
  display: none;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  gap: 15px;

  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2.4vh;
  color: $black2;

  position: fixed;
  bottom: 4%;
  right: 3.85%;
  cursor: pointer;

  @include mdd {
    max-width: 280px;
  }
}

.video-prod__button-text {
  text-align: left;
  min-width: 165px;
  width: min-content;
}

.video-prod__wrapper-img-link {
  cursor: pointer;
  position: relative;

  &:hover {
    svg {
      path {
        fill: $yellow;
      }
    }
  }
}

.video-prod__play-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.video-prod__wrapper-play-img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  background-color: $white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    path {
      transition: .4s;
    }
  }
}


// animations


.hiddenVideoProd {
  @include animationMixin(hiddenVideoProd, 2s)
}
  
@keyframes hiddenVideoProd {
  from {
    bottom: 16.4%;
  }
  to {
    bottom: -83.6%;
  }
}

.showVideoProd {
  @include animationMixin(showVideoProd, 2s)
}

@keyframes showVideoProd {
  from {
    bottom: -83.6%;
  }
  to {
    bottom: 16.4%;
  }
}


.transformToTopVideoProd {
  @include animationMixin(transformToTopVideoProd, 2s)
}

@keyframes transformToTopVideoProd {
  from {
    bottom: 16.4%;
  }
  to {
    bottom: 116.4%;
  }
}

.transformToDownVideoProd {
  @include animationMixin(transformToDownVideoProd, 2s)
}

@keyframes transformToDownVideoProd {
  from {
    bottom: 116.4%;
  }
  to {
    bottom: 16.4%;
  }
}

  


.hiddenVideoProdTitle {
  @include animationMixin(hiddenVideoProdTitle, 2s)
}

@keyframes hiddenVideoProdTitle {
  from {
    top: 23.7%;
  }
  to {
    top: 123.7%;
  }
}

.showVideoProdTitle {
  @include animationMixin(showVideoProdTitle, 2s)
}

@keyframes showVideoProdTitle {
  from {
    top: 123.7%;
  }
  to {
    top: 23.7%;
  }
}


.transformToTopVideoProdTitle {
  @include animationMixin(transformToTopVideoProdTitle, 2s)
}

@keyframes transformToTopVideoProdTitle {
  from {
    top: 23.7%;
  }
  to {
    top: -76.3%;
  }
}

.transformToDownVideoProdTitle {
  @include animationMixin(transformToDownVideoProdTitle, 2s)
}

@keyframes transformToDownVideoProdTitle {
  from {
    top: -76.3%;
  }
  to {
    top: 23.7%;
  }
}




.hiddenVideoProdButton {
  @include animationMixin(hiddenVideoProdButton, 2s)
}

@keyframes hiddenVideoProdButton {
  from {
    bottom: 4%;
  }
  to {
    bottom: -104%;  
  }
}

.showVideoProdButton {
  @include animationMixin(showVideoProdButton, 2s)
}

@keyframes showVideoProdButton {
  from {
    bottom: -104%;
  }
  to {
    bottom: 4%;
  }
}


.transformToTopVideoProdButton {
  @include animationMixin(transformToTopVideoProdButton, 2s)
}

@keyframes transformToTopVideoProdButton {
  from {
    bottom: 4%;
  }
  to {
    bottom: 104%;
  }
}

.transformToDownVideoProdButton {
  @include animationMixin(transformToDownVideoProdButton, 2s)
}

@keyframes transformToDownVideoProdButton {
  from {
    bottom: 104%;
  }
  to {
    bottom: 4%;
  }
}
