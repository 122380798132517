@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.what-doing-tablet__content {
  width: 100%;
  margin-bottom: 5%;

  &-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
    text-transform: uppercase;
    color: $black2;
    position: relative;
    font-family: "FavoritC-Bold", 'Inter', sans-serif;
    max-width: 500px;

    @include Tablet {
      font-size: 28px;
      line-height: 36px;
    }

    @include Phone {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 4%;

    @include Phone {
      gap: 14px;
    }
  }

  &-button {
    margin-top: 2.5%;
    outline: none;
    border: none;
    padding: 2% 32px;
    width: max-content;
    background: $yellow;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.1;
    color: $black2;
    font-family: "FavoritBookC", 'Source Sans Pro', sans-serif;
    cursor: pointer;
    gap: 6px;

    img {
      @include Tablet {
        width: 34px;
      }
    }

    span {
      text-align: left;
      padding-left: 24px;
    }

    @include Tablet {
      font-size: 16px;
      padding: 12px 16px;
    }

    @include Phone {
      background-size: 30px;
      font-size: 16px;
      align-items: center;
    }
  }
}

.what-doing-tablet__title-last-word {
  position: relative;

  &::after {
    content: '';
    display: block;
    background-color: $yellow;
    width: 132px;
    height: 6px;
    position: absolute;
    right: 0;

    @include Tablet {
      width: 94px;
      height: 4px;
    }
  }
}