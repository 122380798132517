@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.who-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.90%;
  display: none;
  font-family: 'FavoritC-Bold', 'Inter', sans-serif;

  @include Phone {
    margin-top: 12%;
  }

  &__img {
    max-width: 255px;
    width: 100%;
    height: 18vh;

    @include xs {
      width: 180px;
    }

    @include Tablet {
      width: 120px;
    }

    @include Phone {
      width: 93px;
      height: 72px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 8.15vh;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $white;
    padding-top: 30px;
    text-align: center;
    white-space: nowrap;

    @include xs {
      font-size: 4rem;
    }

    @include Tablet {
      font-size: 3.25rem;
      padding-top: 16px;
    }
    @include Phone {
      font-size: 2.25rem;
    }
  }
}
