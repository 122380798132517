@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.what-doing-tablet-list {
  width: 100%;
  background: $lightGray2;
  display: flex;
  align-items: center;

  &__img {
    height: 15vh;
    max-width: 135px;
    width: 100%;
    object-fit: cover;

    @include Tablet {
      height: 11vh; 
    }
    @include Phone {
      max-width: 80px;
      width: 100%;
      height: 80px;
    }
  }

  &__wrapp {
    display: flex;
    flex-direction: column;
    margin-left: 3%;
  }

  &__title {
    font-weight: 400;
    font-size: 22px;
    color: $black2;
    position: relative;
    font-family: "FavoritC", 'Source Sans Pro', sans-serif;
    position: relative;

    @include Tablet {
      font-size: 16px;
      line-height: 20px;
    }

    &::after {
      content: '';
      display: block;
      background-color: $yellow;
      width: 40px;
      height: 3px;
      position: absolute;
      left: 0;
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: $black2;
    margin-top: 10px;
    font-family: "FavoritLightC", 'Source Sans Pro', sans-serif;

    @include Tablet {
      font-size: 16px;
    }
    @include Phone {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
