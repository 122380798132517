@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.what-doing-tablet__wrapp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh;
  padding: 14% 30px 20px;

  @include Tablet {
    padding-top: 18%;
  }

  @include MiniTablet {
    padding-top: 22%;
  }

  @include Phone {
    padding: 25% 16px 16px;
  }
}

.what-doing-tablet__title {
  color: $black;
  font-weight: 300;
  font-size: 32px;
  letter-spacing: 0.08em;
  left: 3.5%;
  bottom: 6.2%;
  z-index: 2;
  left: -15.5%;
  bottom: 41.2%;
  font-family: "FavoritLightC", 'Source Sans Pro', sans-serif;
  margin-bottom: 2%;

  @include Phone {
    font-size: 16px;
  }

  &-ampersand {
    color: $yellow;
  }

  &-we {
    color: $yellow;
    font-family: "FavoritC-Bold", 'Inter', sans-serif;
    margin-left: 10px;
  }
}
  