@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.swiper-design-slide {
  width: 100vw;
  height: 62vh;
  overflow: hidden;
  object-fit: cover;
}

.swiper-design {
  display: flex;
  align-items: center;
}

.design-installation__title {
  max-width: 1320px;
  width: 100%;
  top: 21%;
  font-size: 4rem;
  line-height: 1.1;

  .swiper-main__ampersand {
    font-size: 4rem;
  }
}

.swiper-design-slide {
  height: 57vh;
}

.design-installation__wrapper {
  bottom: 5.2%;

  .swiper-button-prev,
  .swiper-button-next {
    top: 43.5%;
  }
}


.swiper-design__descr {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: $black2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  left: 7.14%;
  bottom: 5.18%;

  @include mdd {
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    width: 300px;
    height: 4px;
    background: $yellow;
    position: absolute;
    bottom: -14px;

    @include mdd {
      width: 270px;
      height: 3px;
      bottom: -8px;
    }
  }
}


// animations


.hiddenDesignInstallation {
  @include animationMixin(hiddenDesignInstallation, 1.6s)
}

@keyframes hiddenDesignInstallation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.showDesignInstallation {
  @include animationMixin(showDesignInstallation, 2s)
}

@keyframes showDesignInstallation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hiddenDesignInstallationTitle {
  @include animationMixin(hiddenDesignInstallationTitle, 1.8s)
}

@keyframes hiddenDesignInstallationTitle {
  from {
    top: 21%;
  }
  to {
    top: -79%;
  }
}

.showDesignInstallationTitle {
  @include animationMixin(showDesignInstallationTitle, 1.8s)
}

@keyframes showDesignInstallationTitle {
  from {
    top: -79%;
  }
  to {
    top: 21%;
  }
}



.hiddenDesignInstallationDescrBottom {
  @include animationMixin(hiddenDesignInstallationDescrBottom, 1.6s)
}

@keyframes hiddenDesignInstallationDescrBottom {
  from {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    transform: translateX(-500px);
    opacity: 1;
  }
}

.showDesignInstallationDescrBottom {
  @include animationMixin(showDesignInstallationDescrBottom, 2s)
}

@keyframes showDesignInstallationDescrBottom {
  from {
    transform: translateX(-500px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
