@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.header__rectangles {
  position: absolute;
  right: 3%;
  top: -15.5%;
  width: 360px;
  height: 360px;
  background: $yellow;
  transform: rotate(45deg);

  @include mdd {
    top: -7.5%;
    right: 1%;
    width: 320px;
    height: 320px;
  }
  @include md {
    width: 260px;
    height: 260px;
  }

  @include xs {
    display: none;
  }
}

.header__rectangle-gradient {
  width: 249.64px;
  height: 24px;
  position: absolute;
  right: 40%;
  top: 110%;
  background: linear-gradient(270.02deg, #E8B449 -0.01%, #FFFFFF 99.22%);
  transform: rotate(0deg)
}

.header__rectangle-border {
  position: absolute;
  width: 249.64px;
  height: 24px;
  border: 1px solid $black;
  transform: rotate(0deg);
  right: 20%;
  top: 114%;
}
