@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.queen-mary-tablet {
  position: relative;
  height: 100vh;
  background-image: url(../../../images/eye_comm_ws.png);
  padding: 0 30px;

  @include Phone {
    padding: 0 16px;
  }

  &__titles {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 3vh;
    margin-bottom: 4%;
    color: $black2;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include Phone {
      font-size: 24px;
      line-height: 40px;
      gap: 0;
    }
  }
}

.queen-mary-tablet__top-content {
  position: absolute;
  top: 15%;

  @include Phone {
    top: 10%;
  }
}

.queen-mary-tablet__text {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2vh;
  color: $black2;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-right: 16px;

  @include Phone {
    font-size: 16px;
    line-height: 1.2;
    gap: 15px;
  }
}

.queen-mary-tablet__laptop {
  position: absolute;
  bottom: 5%;
  transform: translate(-50%, 0);
  left: 50%;
  width: 60vw;
  z-index: 2;
  height: 42vh;

  @include Phone {
    bottom: 1%;
    width: 88vw;
  }
}

.queen-mary-tablet__rhomb {
  position: absolute;
  right: 0;
  bottom: 10%;
  width: 30vw;
  height: 40vh;

  @include Phone {
    bottom: 18%;
  }
}
