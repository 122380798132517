@import '../../../../utils/mixins.scss';

.creation-packs-tablet {
  position: relative;
  // height: 100vh;
}

.creation-packs-tablet__slide {
  overflow: hidden;
  width: 100%;
}

.creation-packs-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  gap: 12px;
}

.creation-packs-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;

  &-title {
    flex-direction: column;
    flex-wrap: nowrap;

    @include TabletMax {
      font-size: 46px;
    }
    @include Tablet {
      font-size: 33px;
    }

    @include MiniTablet {
      font-size: 21px;
    }
  }
}
