@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.roche__rombh-border {
  position: fixed;
  width: 30vw;
  height: 30vw;
  border: 130px solid $yellow;
  transform: rotate(45deg);
  right: 0%;
  top: -20%;
  display: none;
}

.roche__title {
  position: fixed;
  flex-direction: column;
  max-width: 706px;
  width: 100%;
  left: 3.75%;
  bottom: 23.7%;
  display: none;

  @include md {
    max-width: 495px;
  }

  &-top {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 6vh;
    line-height: 1.1;
    color: $black2;

    @include md {
      line-height: 55px;
    }
  }
  &-main {
    font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 6vh;
    line-height: 1;
    color: $black2;
    margin-top: 16px;

    @include md {
      font-size: 3rem;
    }
  }
}

.roche__text {
  font-family: 'FavoritBookC', 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 38px;
  color: $black2;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 40px;

    @include mdd {
    font-size: 1.6rem;
    line-height: 27px;
  }
}

.roche__phone {
  position: fixed;
  right: 15.89%;
  top: 11.85%;
  z-index: 1;
  display: none;
  height: 81vh;
}

.roche__dots {
  position: fixed;
  right: 11.6%;
  bottom: 58%;
  display: none;
}

.roche__strokes {
  position: fixed;
  right: 9.3%;
  bottom: 24.5%;
  display: none;
}

.roche__waves {
  position: fixed;
  right: 6.93%;
  top: 15.18%;   
  display: none;
}


// animations


.hiddenDescrRoche {
  @include animationMixin(hiddenDescrRoche, 1.6s)
}

.showDescrRoche {
  @include animationMixin(showDescrRoche, 1.8s)
}

@keyframes hiddenDescrRoche {
  from {
    bottom: 23.7%;
  }
  to {
    bottom: -76.3%;
  }
}

@keyframes showDescrRoche {
  from {
    bottom: -76.3%;
  }
  to {
    bottom: 23.7%;
  }
}
  

.hiddenPhoneRoche {
  @include animationMixin(hiddenPhoneRoche, 1.6s)
}

.showPhoneRoche {
  @include animationMixin(showPhoneRoche, 1.8s)
}

@keyframes hiddenPhoneRoche {
  from {
    top: 11.85%;
  }
  to {
    top: -88.15%;
  }
}

@keyframes showPhoneRoche {
  from {
    top: -88.15%;
  }
  to {
    top: 11.85%;
  }
}


.hiddenStrokesRoche {
  @include animationMixin(hiddenStrokesRoche, 1.6s)
}

.showStrokesRoche {
  @include animationMixin(showStrokesRoche, 1.8s)
}

@keyframes hiddenStrokesRoche {
  from {
    bottom: 24.5%;
  }
  to {
    bottom: -75.5%;
  }
}

@keyframes showStrokesRoche {
  from {
    bottom: -75.5%;
  }
  to {
    bottom: 24.5%;
  }
}


.hiddenRhombRoche {
  @include animationMixin(hiddenRhombRoche, 1.6s)
}

.showRhombRoche {
  @include animationMixin(showRhombRoche, 1.8s)
}

@keyframes hiddenRhombRoche {
  from {
    top: -20%;
  }
  to {
    top: 120%;
  }
}

@keyframes showRhombRoche {
  from {
    top: 120%;
  }
  to {
    top: -20%;
  }
}



.hiddenDotsRoche {
  @include animationMixin(hiddenDotsRoche, 1.6s)
}

.showDotsRoche {
  @include animationMixin(showDotsRoche, 1.8s)
}

@keyframes hiddenDotsRoche {
  from {
    bottom: 58%;
  }
  to {
    bottom: -42%;
  }
}

@keyframes showDotsRoche {
  from {
    bottom: -42%;
  }
  to {
    bottom: 58%;
  }
}



.hiddenWavesRoche {
  @include animationMixin(hiddenWavesRoche, 1.6s)
}

.showWavesRoche {
  @include animationMixin(showWavesRoche, 1.8s)
}

@keyframes hiddenWavesRoche {
  from {
    opacity: 1;
    top: 15.18%;  
  }
  to {
    opacity: 1;
    top: 115.18%;
  }
}

@keyframes showWavesRoche {
  from {
    opacity: 1;
    top: 115.18%;
  }
  to {
    opacity: 1;
    top: 15.18%;  
  }
}
