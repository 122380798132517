@import '../../utils/variables.scss';
@import '../../utils/mixins.scss';

.menu {
  background-image: url(../../images/eye_comm_ws.png);
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  animation-name: openMenu;
  z-index: 3000;

  &__none {
    animation-name: closeMenu;
    animation-duration: 0.2s;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 71px 0 63px;
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include md {
      padding: 20px 50px 0 50px;
    }

    @include xs {
      padding: 20px 30px 0 30px;
    }
    @include Phone {
      padding: 20px 16px 0;
    }

    &-logo {
      @include Tablet {
        width: 220px;
      }
      @include Phone {
        width: 126px;
      }
    }
  }

  &__close {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    @include mdd {
      width: 60px;
      height: 60px;
    }
    @include MiniTablet {
      width: 40px;
      height: 40px;
    }

    img {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;

      @include mdd {
        width: 20px;
      }
      @include MiniTablet {
        width: 12px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 60%;
    position: absolute;
    top: 13%;
    bottom: 10%;
    left: 27.4%;
    animation-name: openMenuList;
    animation-duration: 1s;
    z-index: 5;

    max-height: 860px;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
    
    @include md {
      top: 14%;
    }
    @include xs {
      height: auto;
      width: 100%;
      padding: 0 40px;
      left: 0;
      top: 16%;
    }

    @include Phone {
      padding: 0 16px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: .3s;
      cursor: pointer;
      position: relative;

      &:hover {
        
        & + .menu__list-separate {
          border-bottom: 1px solid rgba(255, 255, 255);
        } 

        .menu__list-item-arrow-hover {
          display: block;
        }
        .menu__list-item-arrow {
          display: none;
        }
      }

      &-title {
        font-family: 'FavoritC', 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-size: 3rem;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $white;
        margin-right: 10px;

        @include mdd {
          font-size: 2.2rem;
        }
        @include md {
          font-size: 2rem;
        }
        @include Tablet {
          font-size: 28px;
        }
        @include Phone {
          font-size: 20px;
        }
      }

      &-arrow-hover {
        display: none;
      }

      &-arrow-hover,
      &-arrow {
        position: absolute;
        right: 0;
        top: 5%;
        
        @include Tablet {
          width: 30px;
        }
        @include Phone {
          width: 20px;
          height: 20px;
        }
      }
    }
    
    &-separate {
      border-bottom: 1px solid rgba(255, 255, 255, 0.32);
      transition: .3s;
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  &__heroImg {
    position: absolute;
    bottom: 0;
    left: 0;
    animation-name: showAllDies;
    animation-duration: 0.2s;
    height: 60vh;

    @include xs {
      display: none;
    }
  }

  &__strokes {
    position: absolute;
    left: 0;
    top: 36%;
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include xs {
      display: none;
    }
  }

  &__waves {
    position: absolute;
    left: 16.46%;
    top: 25.37%;
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include mdd {
      width: 80px;
      height: 50px;
    }
    @include xs {
      top: auto;
      bottom: 3%;
      left: 3%;
    }
    @include Phone {
      width: 50px;
      height: 32px;
    }
  }

  &__rhomb-border {
    position: absolute;
    width: 219.86px;
    height: 219.86px;
    top: 26.85%;
    left: 94.5%;
    border: 64px solid $yellow;
    transform: rotate(45deg);
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include xs {
      display: none;
    }
  }

  &__rhomb {
    position: absolute;
    width: 33vw;
    height: 33vw;
    left: -16%;
    bottom: 4%;
    background: $yellow;
    transform: rotate(45deg);
    z-index: -1;
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include xs {
      display: none;
    }
  }

  &__rectangles {
    position: absolute;
    width: 249.64px;
    height: 24px;
    right: 2%;
    bottom: 0;
    background: linear-gradient(270.02deg, #E8B449 -0.01%, rgba(232, 180, 73, 0) 99.22%);
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
    animation-name: showAllDies;
    animation-duration: 0.2s;

    @include Tablet {
      width: 62.31px;
      height: 5.99px;
      background: linear-gradient(270.02deg, #E8B449 -0.01%, #FFFFFF 99.22%);
      transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0);
      bottom: 3%;
      right: 6%;
    }


    &-border {
      position: absolute;
      width: 249.64px;
      height: 24px;
      border: 1px solid $black;
      right: 24%;
      bottom: -50%;

      @include Tablet {
        width: 62.31px;
        height: 5.99px;
        right: -32%;
        bottom: -60%;
      }
    }
  }

  &__play-arrows {
    display: none;

    @include xs {
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: 4%;
    }
  }

  &__rectangle-bottom {
    display: none;

    @include Tablet {
      display: block;
      position: absolute;
      width: 89.85px;
      height: 89.85px;
      bottom: -5%;
      right: -6%;
      background: $yellow;
      transform: matrix(0.71, -0.71, -0.71, -0.71, 0, 0);
    }

    @include Phone {
      right: -13%;
    }
  }
}

.menu__buttons-wrapper {
  display: flex;
  gap: 60px;

  @include MiniTablet {
    gap: 24px;
  }
}


.languages {
  display: flex;
  gap: 8px;
  position: absolute;
  left: 27.4%;
  top: 4%;

  @include xs {
    position: static;
    top: 0;
    left: 0;
  }

  &-uk {
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 56px;

    font-family: 'FavoritC', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $white;
    border: none;
    outline: none;
    cursor: pointer;

    @include MiniTablet {
      height: 40px;
      width: 40px;
      font-size: 14px;
    }
  }
  &-en {
    width: 64px;
    height: 64px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 56px;

    font-family: 'FavoritC', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $white;
    border: none;
    outline: none;
    cursor: pointer;

    @include MiniTablet {
      height: 40px;
      width: 40px;
      font-size: 14px;
    }
  }
}

.is-active {
  width: 64px;
  height: 64px;
  background: $yellow;
  border-radius: 56px;

  font-family: 'FavoritC', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $black2;
  border: none;
  outline: none;
  cursor: pointer;
  
  @include MiniTablet {
    height: 40px;
    width: 40px;
    font-size: 14px;
  }
}


// animations


@keyframes openMenu {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes showAllDies {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeMenu {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@keyframes openMenuList {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
