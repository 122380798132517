@import '../../../../utils/mixins.scss';

.exhibition-tablet {
  position: relative;
  // height: 100vh;
}

.exhibition-tablet__slide {
  overflow: hidden;
  width: 100%;
  // object-fit: cover;
  height: 25vh;

  &:first-child {
    width: 105%;
  }

  @include Phone {
    height: 23vh;
  }

  &-first {
    overflow: hidden;
    width: 100%;
    height: 25vh;
    object-fit: cover;

    @include Phone {
      height: 23vh;
    }
  }
}

.exhibition-tablet__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  overflow: hidden;
}

.exhibition-tablet__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 14%;
  padding: 16% 0;
}
