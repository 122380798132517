@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.corporate-book-slide {
  width: 100vw;
  overflow: hidden;
}

.corporate-book {
  display: flex;
  height: 62vh;
  justify-content: center;

  &__title {
    max-width: 880px;
    width: 100%;
    top: 21%;
  }

  &__left {
    display: flex;
    flex-direction: column;

    &-bottom {
      display: flex;
    }
  }
}

.corporate-book__title {
  font-size: 3.5rem;
  line-height: 1.2;
}

.corporate-book__wrapper {
  bottom: 5%;

  .swiper-button-prev,
  .swiper-button-next {
    top: 49%;
  }
}

.corporate-book__left-one {
  width: 48vw;
  height: 30vh;
  object-fit: cover;
  object-position: center;
}

.corporate-book__left-bottom-first {
  width: 24vw;
  height: 30vh;
  object-fit: cover;
  object-position: center;
}

.corporate-book__left-bottom-second {
  width: 24vw;
  height: 30vh;
  object-fit: cover;
  object-position: center;
}
.corporate-book__right {
  width: 37vw;
  height: 60vh;
  object-fit: cover;
  object-position: center;
}

.corporate-book-swiper {
  display: flex;
  justify-content: center;
}


// animations 


.hiddenCorporateBook {
  @include animationMixin(hiddenCorporateBook, 1.6s)
}
  
@keyframes hiddenCorporateBook {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.showCorporateBook {
  @include animationMixin(showCorporateBook, 2s)
}

@keyframes showCorporateBook {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hiddenCorporateBookTitle {
  @include animationMixin(hiddenCorporateBookTitle, 1.8s)
}

@keyframes hiddenCorporateBookTitle {
  from {
    top: 21%;
  }
  to {
    top: -79%;
  }
}

.showCorporateBookTitle {
  @include animationMixin(showCorporateBookTitle, 1.8s)
}

@keyframes showCorporateBookTitle {
  from {
    top: -79%;
  }
  to {
    top: 21%;
  }
}
