@import '../../../../utils/variables.scss';
@import '../../../../utils/mixins.scss';

.main-content__titles {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 62%;

  @include xs {
    top: 59%;
  }
  @include Tablet {
    top: 60%;
  }
  @include Phone {
    top: 46%;
  }

  &-wrapp {
    display: flex;
    gap: 48px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;

    @include mdd {
      gap: 25px;
    }
    @include Tablet {
      gap: 20px;
    }
    @include Phone {
      margin-bottom: 12px;
      gap: 17px;
    }
  }

  &-main {
    z-index: 5;
    display: flex;
    gap: 48px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin-bottom: 0.2%;
    position: fixed;
    bottom: 50%;
    transform: translate(-50%, -50%);
    left: 50%;

    @include xs {
    }
    @include Tablet {
      flex-direction: column;
      gap: 0;
      bottom: 45%;
    }
    @include Phone {
      bottom: 61%;
    }
  }
}

.main-content__title-main {
  font-weight: 700;
  font-size: 12.5vh;
  color: $white;
  font-family: "FavoritC-Bold", sans-serif;

  @include md {
    font-size: 9vw;
  }
  @include Tablet {
    font-size: 3.2rem;
  }

  @include Phone {
    font-size: 2.5rem;
  }
}

.main-content__title-gray {
  font-weight: 400;
  font-size: 11vh;
  color: $white;
  opacity: 0.24;
  font-family: "FavoritC", sans-serif;

  @include md {
    font-size: 9.5vh;
  }
  @include Tablet {
    font-size: 2.85rem;
  }
  @include Phone {
    font-size: 2.25rem;
  }
}

.main-content__title-center {
  font-weight: 400;
  font-size: 11vh;
  color: $white;
  font-family: "FavoritC", sans-serif;

  @include md {
    font-size: 9.5vh;
  }
  @include Tablet {
    font-size: 2.85rem;
  }
  @include Phone {
    font-size: 2.25rem;
  }
}
